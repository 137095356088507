import {
  parse as parseQuery,
  stringify as stringifyQuery,
} from 'querystring';
import { parse as parseUrl } from 'url';

import React from 'react';

import DefaultAdmin from 'hive-admin';
import { Redirect } from 'react-router-dom';

import Driving from './Driving';
import { Provider as SystemCountryProvider } from './SystemCountry';

import Types from '../modules/types';

export default class Admin extends DefaultAdmin {
  constructor(props) {
    super(props);
    const { client } = this.props;
    if (!client.systemCountryIntercepted) {
      client.systemCountryIntercepted = true;
      client.interceptors.request.use((config = {}) => {
        const { viewer, searchParams } = this.props;
        if (viewer) {
          const systemCountry = viewer.systemCountry || searchParams.sc;
          const { pathname, query: queryString } = parseUrl(config.url);
          const query = parseQuery(queryString);
          const url = `${pathname}?${stringifyQuery({
            ...(query || {}),
            systemCountry,
          })}`;
          return {
            ...config,
            url,
          };
        }
        return config;
      });
      // client.interceptors.response.use((response) => {
      //   const { viewer, searchParams } = this.props;
      //   return response;
      // });
    }
  }

  renderContent(extraProps = {}) {
    return (
      // eslint-disable-next-line react/no-this-in-sfc
      <Driving {...this.props} {...extraProps}>
        {(drivingProps = {}) => super.renderContent({
          ...extraProps,
          ...drivingProps,
        })}
      </Driving>
    );
  }

  render() {
    const {
      client,
      viewer,
      searchParams,
      location,
      isInitializing,
      isAuthorized,
    } = this.props;
    if (viewer) {
      const lastSystemCountry = (
           Types.SYSTEM_COUNTRY_MAP[client.storage.getItem('systemCountry')]
        || Types.SYSTEM_COUNTRY_LIST[0]
      );
      if (viewer.role === 'ADMIN') {
        if (!searchParams.sc || !Types.SYSTEM_COUNTRY_MAP[searchParams.sc]) {
          return (
            <Redirect
              to={`${location.pathname}?${stringifyQuery({
                ...searchParams,
                sc: lastSystemCountry.id,
              })}`}
            />
          );
        }
      } else if (
        viewer.systemCountry
        && searchParams.sc !== viewer.systemCountry
      ) {
        return (
          <Redirect
            to={`${location.pathname}?${stringifyQuery({
              ...searchParams,
              sc: viewer.systemCountry || undefined,
            })}`}
          />
        );
      }
    } else if (
          searchParams.sc
      && !isInitializing
      && !isAuthorized
    ) {
      return (
        <Redirect
          to={`${location.pathname}?${stringifyQuery({
            ...searchParams,
            sc: undefined,
          })}`}
        />
      );
    }
    if (searchParams.sc) {
      client.storage.setItem('systemCountry', searchParams.sc);
    }
    const systemCountry = (
      Types.SYSTEM_COUNTRY_MAP[searchParams.sc]
      || Types.SYSTEM_COUNTRY_MAP[Types.SYSTEM_COUNTRY[0]]
    );
    if (client) {
      client.sc = systemCountry;
    }
    return (
      <SystemCountryProvider systemCountry={systemCountry}>
        {super.render()}
      </SystemCountryProvider>
    );
  }
}
