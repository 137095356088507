import { join as joinPath } from 'path';

import React, { createContext, useContext, useCallback } from 'react';
import styled from 'styled-components';

import { stringify as stringifyQuery } from 'querystring';

import Select from 'antd/lib/select';

import Types from '../modules/types';

export const Context = createContext();

export function Provider({ systemCountry, children }) {
  return (
    <Context.Provider value={systemCountry}>
      {children}
    </Context.Provider>
  );
}

export function useSystemCountry() {
  return useContext(Context);
}

export function Currency({ prefix, suffix }) {
  const systemCountry = useSystemCountry();
  const currency = systemCountry ? systemCountry.currency : '-';
  return `${prefix || ''}${currency}${suffix || ''}`;
}

export function renderCurrency({ prefix, suffix } = {}) {
  return (
    <Currency
      prefix={prefix}
      suffix={suffix}
    />
  );
}

const SwitcherWrapper = styled.div`
  display: flex;
  width: 100%;
  > .ant-select {
    width: 100%;
  }
`;

export function Switcher({ location, searchParams }) {
  const systemCountry = useSystemCountry();
  const value = systemCountry ? systemCountry.id : Types.SYSTEM_COUNTRY[0];
  const onClick = useCallback(
    ({ key: newSystemCountry }) => {
      const newPath = `${joinPath(window.ADMIN_BASE, location.pathname)}?${
        stringifyQuery({ ...searchParams, sc: newSystemCountry })
      }`;
      window.location = newPath;
    },
    [searchParams, location],
  );
  return (
    <SwitcherWrapper>
      <Select value={value}>
        {
          Types.SYSTEM_COUNTRY_LIST.map((item) => (
            <Select.Option
              key={item.id}
              value={item.id}
              onClick={onClick}
            >
              {item.label}
            </Select.Option>
          ))
        }
      </Select>
    </SwitcherWrapper>
  );
}
