/* eslint-disable import/prefer-default-export */

import {
  stringify as stringifyQuery,
} from 'querystring';

import Admin from 'hive-admin';

import PageSingle from 'hive-admin/src/components/PageSingle';
import { PageTracking } from '../routes/tracking';

PageSingle.prototype.__renderFound = PageSingle.prototype.renderFound;
PageTracking.prototype.__renderFound = PageTracking.prototype.renderFound;

(
  PageSingle.prototype.renderFound
) = (
  PageTracking.prototype.renderFound
) = function renderPageSingleFound() {
  const { data, client, location, searchParams } = this.props;
  if (
    data
    && searchParams
    && data.systemCountry
    && data.systemCountry !== searchParams.sc
  ) {
    const newPath = `${location.pathname}?${
      stringifyQuery({ ...searchParams, sc: data.systemCountry })
    }`;
    client.storage.setItem('systemCountry', data.systemCountry);
    window.location = newPath;
    return null;
  }
  return this.__renderFound(this);
};

Admin.addToLibrary(
  'redirect.authorizedNonAdmin',
  destination => ({ viewer }) => {
    if (viewer && viewer.role !== 'ADMIN') {
      return destination || `/users/${viewer._id}`;
    }
    return false;
  },
);
