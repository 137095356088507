import moment from 'moment';

import Admin from 'hive-admin';

export default Admin.compileFromLibrary(['GroupResource', {
  title: 'Providers',
  icon: 'shop',
  path: '/providers',
  redirect: [
    ['redirect.unauthorized'],
    ['redirect.authorizedNonAdmin'],
  ],
  filters: [
    ['FilterField', {
      id: 'search',
      label: null, // 'ID',
      section: 'top',
      buildQuery: (value, builder) => value && builder.add(
        'where',
        {
          OR: [
            { name: { REGEX: value, OPTIONS: 'i' } },
          ],
        }
      ),
      getValueForField: value => value || '',
      getValueForQuery: (value) => {
        value = !value
        ? undefined
        : value.target
        ? value.target.value
        : value;
        return !value || !value.length
        ? undefined
        : value;
      },
      field: ['FieldText', {
        name: 'search',
        placeholder: 'Search',
      }],
      col: {
        xs: { span: 24 },
      },
    }],
    ['FilterPagination', {
      id: 'pagination',
      section: 'bottom',
    }],
  ],
  archiveConfig: {
    title: 'Providers',
    label: 'Providers',
    icon: 'shop',
    createButtonPath: '/providers/create',
    hidden: [
      ['redirect.unauthorized'],
      ['redirect.authorizedNonAdmin'],
    ],
  },
  singleConfig: {
    hidden: true,
    alias: '/providers',
    backButtonPaths: ['/providers'],
  },
  columns: [
    { path: 'name', link: '/providers/:_id' },
    {
      path: 'pricing',
      title: 'Areas Covered',
      render: record => (
        record && record.pricing && Object.keys(record.pricing).length
      ),
    },
    {
      path: 'createdAt',
      title: 'Joined At',
      render: (record) => (
        moment(record.createdAt).format(
          'dddd, MMMM Do YYYY [at] h:mm a'
        )
      ),
    },
  ],
  fields: [
    ['FieldText', {
      name: 'name',
      rules: [['validation.isRequired']],
    }],
    ['FieldAreaPricing', {
      name: 'pricing',
      label: null,
    }],
  ],
}]).pages;
