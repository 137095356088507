import React from 'react';
import styled from 'styled-components';

import {
  UserOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from '@ant-design/icons';

// import Admin from 'hive-admin';
import PageSingle from 'hive-admin/src/components/PageSingle';

// import Button from 'antd/lib/button';
import List from 'antd/lib/list';
import Avatar from 'antd/lib/avatar';
import Descriptions from 'antd/lib/descriptions';

import { renderCurrency } from '../../components/SystemCountry';
import { decimalize } from '../../modules/helpers';

import {
  // GoogleMapsLink,
  PhoneNumberLink,
} from '../../components/Links';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px auto;
  width: 100%;
  max-width: 300px;
  padding: 40px;
  border-radius: 10px;
  background-color: #fefefe;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  text-align: left;
  .ant-descriptions-item {
    display: flex;
    .ant-descriptions-item-label {
      vertical-align: top;
    }
  }
`;

const Spacer = styled.div`
  height: ${({ height = 0 }) => height}px;
`;

const Title = styled.h1`
  font-size: 40px;
  line-height: 1.2;
  font-weight: 700;
`;

export class PageTracking extends PageSingle {
  static config = {
    ...PageSingle.config,
    ClassName: 'Page',
  }

  renderOrderHeader(order) {
    return (
      <Descriptions title="Order" column={1}>
        <Descriptions.Item label="ID">
          {order._id}
        </Descriptions.Item>
        {
          order.cost.cod > 0
          ? (
              <Descriptions.Item label="Cash on delivery">
                {renderCurrency({
                  suffix: ` ${decimalize(order.cost.cod)}`,
                })}
              </Descriptions.Item>
            )
          : null
        }
      </Descriptions>
    );
  }

  renderOrderDetails(title, details) {
    return (
      <Descriptions title={title} column={1}>
        <Descriptions.Item label={<UserOutlined />}>
          {details.name}
        </Descriptions.Item>
        {
          details.phoneNumberString
          ? (
              <Descriptions.Item label={<PhoneOutlined />}>
                <PhoneNumberLink
                  phoneNumberString={details.phoneNumberString}
                />
              </Descriptions.Item>
            )
          : null
        }
        {
          details.address
          ? (
              <Descriptions.Item label={<EnvironmentOutlined />}>
                {details.address.lines}
              </Descriptions.Item>
            )
          : null
        }
      </Descriptions>
    );
  }

  renderOrderItems(items) {
    return (
      <Descriptions title="Items" column={1}>
        <Descriptions.Item>
          <List
            itemLayout="horizontal"
            dataSource={items}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  avatar={(
                    item.image
                    ? <Avatar src={item.image} />
                    : null
                  )}
                  title={`${item.quantity} x ${item.name}`}
                />
              </List.Item>
            )}
          />
        </Descriptions.Item>
      </Descriptions>
    );
  }

  renderFound() {
    const { data: order } = this.props;
    let content = null;
    switch (order.status) {
      case 'DELIVERY':
        content = (
          <>
            <Title>Tracking</Title>
            <Spacer height={20} />
            {this.renderOrderHeader(order)}
            <Spacer height={20} />
            {this.renderOrderDetails('Driver', order.driver, order)}
            <Spacer height={20} />
            {this.renderOrderDetails('Customer', order.deliveryDetails, order)}
            <Spacer height={10} />
            {this.renderOrderItems(order.items)}
          </>
        );
      break;
      default:
        content = (
          <Title alone>
            No tracking info available
          </Title>
        );
      break;
    }
    return <Wrapper>{content}</Wrapper>;
  }
}

export default [config => PageTracking.create(config), {
  title: 'Tracking',
  icon: null,
  path: '/tracking/:id',
  hideSidebar: true,
  hideHeader: true,
  hidden: [() => true],
  redirect: [],
  activity: 'edit',
  editConfig: { actions: [] },
  useTrash: false,
  headerTitle: 'Tracking',
  loadUrl: '/orders/:id/?resolve=true',
  saveMethod: 'post',
  loadExtractData: response => (response ? response.data : null),
  notFoundMessage: 'Order not found!',
}];
