import isArray from 'lodash/isArray';

import getFilterWithCol from '../../helpers/getFilterWithCol';

const getValueForFieldDefault = value => (
  value && value.length
  ? value
  : undefined
);

export const filterPagination = ['FilterPagination', {
  id: 'pagination',
  section: 'bottom',
}];

export const filterNoSignups = ['FilterHidden', {
  id: 'nonSignupUsers',
  build: builder => builder.add('where', { signup: { NE: true } }),
}];

export const filterSearch = ['FilterField', {
  id: 'search',
  label: null, // 'ID',
  section: 'top',
  buildQuery: (value, builder) => value && builder.add(
    'where',
    {
      OR: [
        { name: { REGEX: value, OPTIONS: 'i' } },
        { email: { REGEX: value, OPTIONS: 'i' } },
        { 'phoneNumber.number': { REGEX: value, OPTIONS: 'i' } },
      ],
    }
  ),
  getValueForField: value => value || '',
  getValueForQuery: (value) => {
    value = !value
    ? undefined
    : value.target
    ? value.target.value
    : value;
    return !value || !value.length
    ? undefined
    : value;
  },
  field: ['FieldText', {
    name: 'search',
    placeholder: 'Search name, email, phone number...',
  }],
}];

export const filterRole = ['FilterField', {
  id: 'role',
  label: null,
  section: 'top',
  VALUES_MAP: {
    any: null,
    admin: { role: { EQ: 'ADMIN' } },
    driver: { role: { EQ: 'DRIVER' } },
  },
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('where', { ...filter.VALUES_MAP[value] })
    : null
  ),
  getValueForField: value => (
    value && value.length
    ? value
    : undefined
  ),
  field: ['FieldSelect', {
    name: 'role',
    placeholder: 'Role',
    prepareValueForInput: value => (value ? value : 'any'),
    choices: [{
      label: 'Any Role',
      value: 'any',
    }, {
      label: 'Admin',
      value: 'admin',
    }, {
      label: 'Driver',
      value: 'driver',
    }],
  }],
}];

export const filterActive = ['FilterField', {
  id: 'active',
  label: null,
  section: 'top',
  VALUES_MAP: {
    any: null,
    active: { active: { EQ: true } },
    inactive: { active: { EQ: false } },
  },
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('where', { ...filter.VALUES_MAP[value] })
    : null
  ),
  getValueForField: value => (
    value && value.length
    ? value
    : 'any'
  ),
  field: ['FieldSelect', {
    name: 'active',
    placeholder: 'Active',
    prepareValueForInput: value => (value ? value : 'any'),
    initialValue: 'any',
    choices: [{
      label: 'Any Status',
      value: 'any',
    }, {
      label: 'Active',
      value: 'active',
    }, {
      label: 'Inactive',
      value: 'inactive',
    }],
  }],
}];

export const filterProvider = ['FilterField', {
  id: 'provider',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { provider: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'provider',
    url: '/providers',
    placeholder: 'Providers',
    mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
  }],
}];

export const filterAvailable = ['FilterField', {
  id: 'available',
  label: null,
  section: 'top',
  VALUES_MAP: {
    any: null,
    free: { order: { EQ: null } },
    busy: { order: { NE: null } },
  },
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('where', { ...filter.VALUES_MAP[value] })
    : null
  ),
  getValueForField: value => (
    value && value.length
    ? value
    : undefined
  ),
  field: ['FieldSelect', {
    name: 'available',
    placeholder: 'Availability',
    prepareValueForInput: value => (value ? value : 'any'),
    choices: [{
      label: 'Any Availability',
      value: 'any',
    }, {
      label: 'Available',
      value: 'free',
    }, {
      label: 'Delivering',
      value: 'busy',
    }],
  }],
}];

export const filterAreas = ['FilterField', {
  id: 'area',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { area: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'area',
    url: '/areas',
    placeholder: 'Areas',
    mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
  }],
}];

export const filterPopulateDriver = ['FilterHidden', {
  id: 'populateDriver',
  build: builder => builder.add(
    'populate',
    { provider: true, area: true },
  ),
}];

export const USERS = [
  ...[
    [filterSearch, 8],
    [filterActive, 8],
    [filterRole, 8],
  ].map(([filter, col]) => getFilterWithCol(filter, col)),

  filterPagination,
  filterNoSignups,
];

export const DRIVERS = [
  ...[
    [filterSearch, 8],
    [filterActive, 8],
    [filterProvider, 8],

    [filterAvailable, 12],
    [filterAreas, 12],
  ].map(([filter, col]) => getFilterWithCol(filter, col)),

  filterPagination,
  filterPopulateDriver,
  filterNoSignups,
];
