import getKey from 'lodash/get';

import { stringify as stringifyQuery } from 'querystring';

import React, { Component, useState, useEffect, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';

import AntdModal from 'antd/lib/modal';
import AntdProgress from 'antd/lib/progress';
import AntdDescriptions from 'antd/lib/descriptions';

import {
  BellOutlined,
  EnvironmentOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';

import { Plugins } from '@capacitor/core';

import Admin from 'hive-admin';

import { FIELDS } from '../routes/orders/fields';

import Types from '../modules/types';

const UPDATE_EVERY = 5000;
const AUTOASSIGN_LASTS_SECONDS = Types.AUTOASSIGN_LASTS / 1000;

const { Geolocation } = Plugins;

const ModalHTML = ({ className, ...props }) => (
  <AntdModal
    {...props}
    width="100%"
    className={className}
    wrapClassName={className}
  />
);

const Modal = styled(ModalHTML)`
  &.ant-modal-wrap {
    display: flex;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
    &:before { content: ""; display: none; }
    &:after { content: ""; display: none; }
  }
  &.ant-modal {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    top: auto;
    width: 100%;
    max-width: 400px;
    > div:first-child {
      display: none !important;
    }
    > .ant-modal-content {
      display: flex;
      flex: 1;
      background-color: transparent;
      box-shadow: none;
      > .ant-modal-body {
        display: flex;
        flex: 1;
      }
    }
  }
`;

Modal.defaultProps = {
  maskStyle: { backgroundColor: 'rgba(255, 255, 255, 0.95)' },
};

const ModalWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: fit-content;
  .ant-descriptions {
    .ant-descriptions-title {
      font-weight: 600;
      color: ${({ theme }) => theme.less.textColor};
    }
    .ant-descriptions-item {
      display: flex;
      > .ant-descriptions-item-label,
      > .ant-descriptions-item-content {
        font-size: 16px;
        &:after { content: ""; }
      }
    }
    .ant-list-item {
      border-bottom: 0px;
    }
  }
`;

const TimerWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TimerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TimerTextIcon = styled(BellOutlined)`
  font-size: 60px !important;
`;

const TimerTextSeconds = styled.div`
  font-weight: 600;
  margin-top: 10px;
`;

const Timer = ({ forever, until }) => {
  const { less: { primaryColor } } = useTheme();
  const untilDate = useMemo(
    () => new Date(until),
    [until],
  );
  const secondsLeft = forever ? 0 : Math.floor(
    Math.max(
      0,
      (untilDate.getTime() - Date.now()) / 1000
    ),
  );
  const [flag, setFlag] = useState(0);
  useEffect(
    () => {
      if (!forever) {
        const timeout = setTimeout(() => setFlag(flag + 1), 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
      return undefined;
    },
    [flag, until, forever],
  );
  return (
    <TimerWrapper>
      <AntdProgress
        type="circle"
        percent={(secondsLeft / AUTOASSIGN_LASTS_SECONDS) * 100}
        status="normal"
        width={200}
        format={() => (
          <TimerTextWrapper>
            <TimerTextIcon />
            {
              forever
              ? null
              : (
                  <TimerTextSeconds>
                    {`${secondsLeft.toFixed(0)}`}
                  </TimerTextSeconds>
                )
            }
          </TimerTextWrapper>
        )}
        strokeWidth={8}
        strokeLinecap="square"
        strokeColor={primaryColor}
      />
    </TimerWrapper>
  );
};

const Title = styled.h1`
  font-size: 30px;
  line-height: 100%;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.less.textColor};
  padding: 20px 0px;
  margin: 20px 0px;
`;

const Description = styled(AntdDescriptions)`
  .ant-list-item {
    border-bottom: 0px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  > button:not(:first-child) {
    margin-top: 10px;
  }
`;

const FakeLink = ({ onClick, children, ...props }) => (
  <a
    href="/"
    onClick={(event) => {
      event.preventDefault();
      if (onClick) {
        onClick(event);
      }
    }}
    {...props}
  >
    {children}
  </a>
);

function ModalContent({ order, client, fields, actions }) {
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  return (
    <ModalWrapper>
      <Timer
        forever={!order.assigning.active}
        until={order.assigning.assignedUntil}
      />
      <Title>
        New Delivery
      </Title>
      <Description title={null} column={1}>
        <Description.Item label={<EnvironmentOutlined />}>
          {getKey(order.pickupDetails, 'address.lines')}
        </Description.Item>
        <Description.Item label={<EnvironmentOutlined />}>
          {getKey(order.deliveryDetails, 'address.lines')}
        </Description.Item>
        <Description.Item
          label={
            showMoreInfo
            ? <UpOutlined />
            : <DownOutlined />
          }
        >
          <FakeLink onClick={() => setShowMoreInfo(!showMoreInfo)}>
            {
              showMoreInfo
              ? 'Show Less Info'
              : 'Show More Info'
            }
          </FakeLink>
        </Description.Item>
      </Description>
      {
        showMoreInfo
        ? fields.map(field => field.render({
            data: order,
            client,
            driving: true,
          }))
        : null
      }
      <Actions>
        {actions.map(action => action.render({ data: order, client }))}
      </Actions>
    </ModalWrapper>
  );
}

export default class Driving extends Component {
  constructor(props) {
    super(props);
    this.state = { order: undefined };
    this.request = null;
    this.driver = null;
    this.order = undefined;
    this.fields = Admin.compileFromLibrary(FIELDS, true);
    this.actions = Admin.compileFromLibrary(
      [
        ['ACCEPT', 'Accept', 'start', true],
        ['DENY', 'Deny', 'start', false],
      ].map(([name, title, action, success]) => (
        ['ActionWithRequest', {
          name: title,
          section: 'bottom',
          type: success ? 'primary' : 'danger',
          ghost: success ? false : true,
          getRequestConfig: (requestProps) => ({
            url: `/orders/${requestProps.data._id}/driving/${action}`,
            method: 'POST',
            data: { success },
          }),
          handleSuccess: async (data, successProps) => {
            await this.load();
            if (success) {
              this.props.history.replace(`/orders/${successProps.data._id}`);
            }
          },
          block: true,
          size: 'large',
        }]
      )),
      true,
    );
  }

  componentDidMount() {
    this.onChange();
  }

  componentDidUpdate() {
    this.onChange();
  }

  componentWillUnmount() {
    this.onStop();
  }

  onChange() {
    if (!this.props.viewer) {
      this.onStop();
    } else if (
         this.props.viewer.role === 'DRIVER'
      && this.props.viewer._id !== this.driver
    ) {
      this.onStart();
    }
  }

  onStart() {
    this.onStop();
    this.started = true;
    this.driver = this.props.viewer._id;
    this.load();
  }

  onStop() {
    if (this.started) {
      clearTimeout(this.timeout);
      this.started = false;
      this.request = null;
      this.driver = null;
      this.order = undefined;
      this.setState({ order: undefined });
    }
  }

  load = () => {
    clearTimeout(this.timeout);
    const request = this.request = Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
      maximumAge: UPDATE_EVERY - 100,
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('geolocation error:', error);
      return null;
    })
    .then((location) => {
      const params = {};
      if (location && location.coords) {
        // eslint-disable-next-line no-console
        console.log(
          'geolocation:',
          `https://www.google.com/maps?q=${
            location.coords.latitude
          },${
            location.coords.longitude
          }`,
          location,
        );
        params.available = true;
        params.lat = location.coords.latitude;
        params.lng = location.coords.longitude;
      }
      return this.props.client.request({
        url: `/users/drivers/driving?${stringifyQuery({
          oid: this.order ? this.order._id : null,
          oua: this.order ? this.order.updatedAt : null,
          ...params,
        })}`,
        noLoader: true,
        noLog: true,
        method: 'GET',
      });
    })
    .then(({ data }) => {
      if (this.request === request && data) {
        if (data.order === null && this.order !== null) {
          this.order = null;
          this.setState(state => ({
            order: null,
          }));
        } else if (data.order) {
          const order = this.order = { ...data.order };
          order.updatedAtDate = new Date(order.updatedAt);
          this.setState(state => ({
            order,
          }));
        }
      }
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('driving error:', error);
      return null;
    })
    .then(() => {
      if (this.request === request) {
        this.timeout = setTimeout(this.load, UPDATE_EVERY);
      }
    });
    return request;
  }

  renderModal() {
    const { order } = this.state;
    const isReserved = order && order.status === 'ASSIGNED';
    return (
      <Modal
        key="driving-modal-assigned"
        header={null}
        footer={null}
        visible={isReserved}
        closable={false}
        destroyOnClose
      >
        {
            isReserved
          ? (
              <ModalContent
                key={order._id}
                order={order}
                client={this.props.client}
                fields={this.fields}
                actions={this.actions}
              />
            )
          : null
        }
      </Modal>
    );
  }

  render() {
    const { order } = this.state;
    return (
      <>
        {
          this.props.children({
            drivingOrder: order,
          })
        }
        {this.renderModal()}
      </>
    );
  }
}
