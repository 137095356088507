import React from 'react';
import isArray from 'lodash/isArray';

import Table from 'antd/lib/table';

import Admin from 'hive-admin';
// import Query from 'hive-admin/src/components/Query';
import Field from 'hive-admin/src/components/Field';

import Input from 'antd/lib/input';
import Checkbox from 'antd/lib/checkbox';

import Types from '../modules/types';

const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const ORDER = [1, 2, 3, 4, 5, 6, 0];

const ROWS = ORDER.map(i => ({
  index: i,
  name: DAYS[i],
}));

// const PriceTypeWrapperHTML = ({ disabled, ...props }) => (
//   <div {...props} />
// );

// const PriceTypeWrapper = styled(PriceTypeWrapperHTML)`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   ${({ disabled }) => disabled && css`opacity: 0.4;`}
// `;

export default class FieldWorkingHours extends Field {
  static config = {
    ...Field.config,
    initialValue: Types.DEFAULT_WORKING_HOURS,
  }

  static isDayOpen(value, index) {
    return value[index].length !== 1 || value[index][0] !== -1;
  }

  handleInputChange = (event) => {
    const { nativeEvent, target } = event;
    const { checked, value } = target;
    const {
      dayIndex: dayIndexString,
      dayInput,
    } = nativeEvent.target.dataset;
    const dayIndex = parseInt(dayIndexString, 10);
    const newValue = isArray(this.props.value)
    ? [...this.props.value]
    : this.props.initialValue;
    if (dayInput === 'open') {
      newValue[dayIndex] = checked
      ? [8, 20]
      : [-1];
      this.props.onChange(newValue);
    } else if (dayInput === 'opens' || dayInput === 'closes') {
      const index = ['opens', 'closes'].indexOf(dayInput);
      newValue[dayIndex] = [...newValue[dayIndex]];
      newValue[dayIndex][index] = value;
      this.props.onChange(newValue);
    }
  }

  render() {
    const value = this.props.value || this.props.initialValue;
    return (
      <Table
        dataSource={ROWS}
        size="middle"
        rowKey="index"
        showHeader
        pagination={false}
        bordered
      >
        <Table.Column
          key="day"
          title="Day"
          render={({ name }) => name}
        />
        <Table.Column
          key="open"
          title="Open"
          align="center"
          render={({ index }) => (
            <Checkbox
              size="small"
              data-day-index={index}
              data-day-input="open"
              onChange={this.handleInputChange}
              checked={FieldWorkingHours.isDayOpen(value, index)}
            />
          )}
        />
        {
          ['opens', 'closes'].map((input, i) => (
            <Table.Column
              key={input}
              title={`${input[0].toUpperCase()}${input.slice(1)}`}
              render={({ index }) => {
                const open = FieldWorkingHours.isDayOpen(value, index);
                return (
                  <Input
                    size="small"
                    disabled={!open}
                    data-day-index={index}
                    data-day-input={input}
                    suffix={open ? 'h' : undefined}
                    placeholder={
                      !open
                      ? 'Closed'
                      : input === 'opens' ? '8' : '20'
                    }
                    onChange={this.handleInputChange}
                    value={open ? value[index][i] : undefined}
                  />
                );
              }}
            />
          ))
        }
      </Table>
    );
  }
}

Admin.addToLibrary(
  'FieldWorkingHours',
  config => FieldWorkingHours.create(config),
);
