import React, { Component, PureComponent } from 'react';
import styled from 'styled-components';

import Select from 'antd/lib/select';
import Input from 'antd/lib/input';
import Icon from 'antd/lib/icon';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import Admin from 'hive-admin';
import Field from 'hive-admin/src/components/Field';

import './FieldGoogleAddress.less';

const DEFAULT_VALUE = {
  line1: '',
  coordinates: [
    13.718345629452529,
    47.67618982807602,
  ],
};

const PostalCodeAndCityWrapper = styled.div`
  display: flex;
  margin-top: 15px;
`;

const PostalCodeAndCityDivider = styled.div`
  display: flex;
  width: 25px;
`;

export class GoogleAutocompleteProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.result = [];
    this.mutable = {};
    this.mutable.service = new global.google.maps.places.AutocompleteService();
    this.mutable.timeout = null;
    this.mutable.request = null;
    this.mutable.unmounted = false;
  }

  componentDidMount() {
    this.reload();
  }

  componentDidUpdate(props) {
    if (props.search !== this.props.search) {
      this.reload();
    }
  }

  componentWillUnmount() {
    this.mutable.unmounted = true;
  }

  updateResult(result = {}) {
    if (!this.mutable.unmounted) {
      this.setState({ result });
    }
  }

  load = ({ search } = {}) => {
    const request = this.mutable.request = () => {
      if (isString(search) && search.length) {
        // this.state.service.getQueryPredictions(
        this.mutable.service.getPlacePredictions(
          {
            ...(this.props.params || {}),
            input: search,
          },
          (predictions, status) => {
            if (request === this.mutable.request) {
              if (status !== global.google.maps.places.PlacesServiceStatus.OK) {
                console.error(status); // eslint-disable-line no-console
              } else {
                this.updateResult(predictions.map(prediction => ({
                  label: prediction.description,
                  value: prediction.description,
                  placeId: prediction.place_id,
                })));
              }
            }
          },
        );
      }
    };
    request();
  }

  reload = (props = {}) => {
    props = Object.assign({}, this.props, props);
    clearTimeout(this.mutable.timeout);
    if (!this.mutable.unmounted) {
      this.mutable.timeout = setTimeout(
        () => this.load(props),
        this.props.throttle || 0,
      );
    }
  }

  render() {
    return this.props.children({
      result: this.state.result,
      reload: this.reload,
    });
  }
}

export class GoogleMap extends Component {
  static defaultProps = {
    lng: 13.718345629452529,
    lat: 47.67618982807602,
    zoom: 4,
    disabled: false,
    onChange: ([lng, lat]) => console.log(lng, lat), // eslint-disable-line no-console
  }

  constructor(props) {
    super(props);
    this.mutable = {
      container: null,
      lng: this.props.lng,
      lat: this.props.lat,
    };
    global.MAP = this;
  }

  componentDidMount() {
    this.mutable.map = new global.google.maps.Map(
      this.mutable.container,
      {
        scaleControl: true,
        center: { lng: this.mutable.lng, lat: this.mutable.lat },
        zoom: 15,
        disableDefaultUI: true,
        gestureHandling: 'cooperative',
        // styles: googleMapStyles,
        mapId: 'be57404401eb7ba2',
      },
    );
    this.mutable.map.addListener('dragend', () => {
      const center = this.mutable.map.getCenter();
      this.mutable.lng = center.lng();
      this.mutable.lat = center.lat();
      this.props.onChange([this.mutable.lng, this.mutable.lat]);
    });
  }

  shouldComponentUpdate(props) {
    if (props.lng !== this.mutable.lng || props.lat !== this.mutable.lat) {
      this.mutable.lng = props.lng;
      this.mutable.lat = props.lat;
      this.mutable.map.setCenter({
        lng: props.lng,
        lat: props.lat,
      });
      this.mutable.map.setZoom(15);
    }
    return false;
  }

  render() {
    return (
      <div className="field-google-map" data-disabled={this.props.disabled}>
        <div
          className="field-google-map-container"
          ref={(ref) => { this.mutable.container = ref; }}
        />
        <Icon className="field-address-center-icon" type="environment" />
      </div>
    );
  }
}

export default class FieldGoogleAddress extends Field {
  static config = {
    ...Field.config,
    initialValue: DEFAULT_VALUE,
    getChoiceValue: choice => (
      isObject(choice)
      ? choice.value
      : `${choice.value}`
    ),
    getChoiceLabel: choice => (
      isObject(choice)
      ? choice.label
      : `${choice}`
    ),
    renderChoices: (field, props, choices) => choices.map(
      choice => props.renderChoice(choice, field, props)
    ),
    choiceClass: Select.Option,
    renderChoice: (choice, field, props) => (
      <props.choiceClass
        key={props.getChoiceValue(choice, field, props)}
        value={props.getChoiceValue(choice, field, props)}
      >
        {props.getChoiceLabel(choice, field, props)}
      </props.choiceClass>
    ),
    placeholder: 'Type to search for address',
    extraGeocodingParams: {},
    getExtraGeocodingParams: props => (props.extraGeocodingParams || {}),
    extraAutocompleteParams: {},
    getExtraAutocompleteParams: props => {
      if (props.client && props.client.sc) {
        return {
          ...(props.extraAutocompleteParams || {}),
          componentRestrictions: {
            country: (
                props.client && props.client.sc
              ? props.client.sc.country
              : undefined
            ),
          },
        };
      }
      return props.extraAutocompleteParams || {};
    },
    line2Props: {},
    getLine2Props: props => props.line2Props,
    postalCodeProps: {},
    getPostalCodeProps: props => props.postalCodeProps,
    cityProps: {},
    getCityProps: props => props.cityProps,
  }

  static inputPropsMap = {
    ...Field.inputPropsMap,
    dataSource: true,
    placeholder: true,
    reload: true,
  }

  constructor(props) {
    super(props);
    this.state = {
      geocoder: new global.google.maps.Geocoder(),
      search: '',
    };
  }

  geocodeAddress(line1, cb) {
    if (line1 !== (this.props.value || {}).line1) {
      const geocodingRequest = this.state.geocodingRequest = () => {
        if (isString(line1) && line1.length) {
          const params = this.props.getExtraGeocodingParams(this.props);
          this.state.geocoder.geocode(
            { ...params, address: line1 },
            (results, status) => {
              if (geocodingRequest === this.state.geocodingRequest) {
                if (status !== 'OK') {
                  console.error(status); // eslint-disable-line no-console
                } else if (results && results[0] && results[0].geometry) {
                  const result = results[0];
                  const coordinates = [
                    result.geometry.location.lng(),
                    result.geometry.location.lat(),
                  ];
                  const extras = {};
                  // if (result.address_components) {
                  //   const cityComponent = result.address_components.find(
                  //     component => component.types.includes('locality')
                  //   );
                  //   if (cityComponent) {
                  //     extras.city = cityComponent.long_name;
                  //   }
                  // }
                  cb(coordinates, extras);
                }
              }
            }
          );
        }
      };
      geocodingRequest();
    }
  }

  handleCoordinatesChange = (coordinates) => {
    this.props.onChange({
      ...(this.props.initialValue || {}),
      ...(this.props.value || {}),
      coordinates,
    });
  }

  handleLine1Change = (line1) => {
    this.geocodeAddress(
      line1,
      (coordinates, extras = {}) => this.props.onChange({
        ...(this.props.initialValue || {}),
        ...(this.props.value || {}),
        ...extras,
        line1,
        coordinates,
      }),
    );
  }

  handleLine2Change = (ev) => {
    if (ev && ev.target) {
      this.props.onChange({
        ...(this.props.initialValue || {}),
        ...(this.props.value || {}),
        line2: ev.target.value || '',
      });
    }
  }

  handlePostalCodeChange = (ev) => {
    if (ev && ev.target) {
      this.props.onChange({
        ...(this.props.initialValue || {}),
        ...(this.props.value || {}),
        postalCode: ev.target.value || '',
      });
    }
  }

  handleCityChange = (ev) => {
    if (ev && ev.target) {
      this.props.onChange({
        ...(this.props.initialValue || {}),
        ...(this.props.value || {}),
        city: ev.target.value || '',
      });
    }
  }

  renderInput(props, extras) {
    const {
      type,
      addonBefore,
      addonAfter,
      ...restProps
    } = props;
    const {
      dataSource,
      reload,
      value,
    } = extras;
    return (
      <Select
        showSearch
        key={props.name}
        {...restProps}
        addonBefore={this.props.addonBefore}
        value={value && value.line1 ? value.line1 : undefined}
        onSearch={search => this.setState({ search })}
        onFocus={() => reload()}
        onChange={this.handleLine1Change}
        placeholder={this.props.placeholder || 'Address, building or area'}
      >
        {
          this.props.renderChoices(
            this,
            this.getStatefullProps(),
            isArray(dataSource) ? dataSource : [],
          )
        }
      </Select>
    );
  }

  render() {
    const value = {
      ...DEFAULT_VALUE,
      ...(this.props.value || {}),
    };
    const line2Props = this.props.getLine2Props(this.props);
    const postalCodeProps = this.props.getPostalCodeProps(this.props);
    const cityProps = this.props.getCityProps(this.props);
    return (
      <div className="field-address">
        <GoogleAutocompleteProvider
          search={this.state.search}
          params={this.props.getExtraAutocompleteParams(this.props)}
        >
          {
            ({
              result: dataSource,
              reload,
            }) => super.render(
              { dataSource, value, reload },
              { dataSource, value, reload },
            )
          }
        </GoogleAutocompleteProvider>
        <Input
          placeholder="Entrance, floor, appartment, etc."
          {...line2Props}
          style={{
            marginTop: '15px',
            ...(line2Props.style || {}),
          }}
          value={value && value.line2 ? value.line2 : ''}
          onChange={this.handleLine2Change}
        />
        <PostalCodeAndCityWrapper>
          <Input
            placeholder="Postal Code"
            autoComplete="new-postal-code"
            {...postalCodeProps}
            style={{ ...(postalCodeProps.style || {}) }}
            value={value && value.postalCode ? value.postalCode : ''}
            onChange={this.handlePostalCodeChange}
          />
          <PostalCodeAndCityDivider />
          <Input
            placeholder="City"
            autoComplete="new-city"
            {...cityProps}
            style={{ ...(cityProps.style || {}) }}
            value={value && value.city ? value.city : ''}
            onChange={this.handleCityChange}
          />
        </PostalCodeAndCityWrapper>
        <GoogleMap
          lng={value.coordinates[0]}
          lat={value.coordinates[1]}
          disabled={this.props.disabled}
          onChange={this.handleCoordinatesChange}
          style={
            this.props.disabled
            ? { pointerEvents: 'none', opacity: 0.3 }
            : {}
          }
        />
      </div>
    );
  }
}

Admin.addToLibrary(
  'FieldGoogleAddress',
  config => FieldGoogleAddress.create(config),
);
