import Types from '../modules/types';

export default Types.ROLE.reduce(
  (agr, role) => {
    ['===', '!=='].forEach(op => ['user', 'viewer'].forEach((type) => {
      agr[`${
        type
      }${
        op === '===' ? 'Is' : 'IsNot'
      }${
        role[0]
      }${
        role.slice(1).toLowerCase()
      }`] = (
          type === 'user'
        ? (props) => {
            let value = null;
            if (props.form) {
              value = props.form.getFieldValue('role');
            }
            if (!value && props.data) {
              value = props.data.role;
            }
            return op === '==='
            ? value === role
            : value !== role;
          }
        : [
            'condition.checkViewer',
            'role',
            op,
            role,
          ]
      );
    }));
    return agr;
  },
  {},
);
