import getKey from 'lodash/get';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isFinite from 'lodash/isFinite';

import React from 'react';

import Divider from 'antd/lib/divider';
import {
  LoadingOutlined,
} from '@ant-design/icons';

import Admin from 'hive-admin';

// eslint-disable-next-line import/no-extraneous-dependencies
import {
  stringify as stringifyQuery,
} from 'query-string';

import getFilterWithCol from '../../helpers/getFilterWithCol';
import createActionFilterLink from '../../helpers/createActionFilterLink';
import { renderCurrency } from '../../components/SystemCountry';

import Types from '../../modules/types';

import { Query as OrdersChartQuery } from '../../components/Charts';

import {
  ADMIN as FILTERS_ADMIN,
  ADMIN_NEW as FILTERS_ADMIN_NEW,
  DRIVER as FILTERS_DRIVER,
} from './filters';

import {
  ADMIN as COLUMNS_ADMIN,
  ADMIN_NEW as COLUMNS_ADMIN_NEW,
  DRIVER as COLUMNS_DRIVER,
} from './columns';

import {
  FIELDS,
} from './fields';

const orderHasNoAction = actionName => props => (
  !props.data
  || !props.data.actions
  || props.data.actions.findIndex(action => action.name === actionName) === -1
);

const drivingActions = [
  ['ACCEPT', 'Accept', 'start', true],
  ['DENY', 'Deny', 'start', false],
  ['PICKUP_SUCCESS', 'Picked up', 'pickup', true, 'Pickup'],
  ['PICKUP_FAILED', 'Failed pickup', 'pickup', false],
  ['DELIVERY_SUCCESS', 'Delivered', 'deliver', true, 'Delivery'],
  ['DELIVERY_FAILED', 'Failed delivery', 'deliver', false],
].map(([name, title, action, success, imagePopoverTitle]) => [
  'ActionWithImage', {
    name: title,
    section: 'bottom',
    type: success ? 'primary' : 'danger',
    ghost: success ? false : true,
    getRequestConfig: props => ({
      url: `/orders/${props.data._id}/driving/${action}`,
      method: 'POST',
      data: {
        success,
        image: (
          props.imagePopoverValueRef
          ? props.imagePopoverValueRef.current
          : undefined
        ),
      },
    }),
    handleSuccess: (data, props) => {
      if (
        !props.viewer
        || (!success && props.viewer.role === 'DRIVER')
      ) {
        props.history.push('/orders');
        return null;
      }
      return props.reload();
    },
    getImagePopoverSkip: () => !imagePopoverTitle,
    // getImagePopoverRequired: props => (
    //   props.viewer && props.viewer.role !== 'ADMIN'
    // ),
    getImagePopoverTitle: props => (
      `Capture ${imagePopoverTitle}${
        props.viewer && props.viewer.role !== 'ADMIN'
        ? ''
        : '?'
      }`
    ),
    getImagePopoverSubmitLabel: () => 'Submit',
    skip: [orderHasNoAction(name)],
  },
]);

export default [
  ['DRIVER', {
    path: '/orders',
    pathNew: null,
    pathReserved: '/orders/:id/reserved',
  }],
  ['ADMIN', {
    path: '/orders',
    pathNew: '/orders/new',
    pathReserved: null,
  }],
].reduce(
  (pages, [role, config]) => {
    const {
      ALL_FILTERS,
      NEW_FILTERS,
    } = (
      role === 'ADMIN'
      ? {
          ALL_FILTERS: FILTERS_ADMIN,
          NEW_FILTERS: FILTERS_ADMIN_NEW,
        }
      : {
          ALL_FILTERS: FILTERS_DRIVER,
          NEW_FILTERS: [],
        }
    );
    pages.push(...Admin.compileFromLibrary(['GroupResource', {
      path: config.path,
      title: 'Orders',
      label: 'Orders',
      icon: 'shopping',
      redirect: [
        ['redirect.unauthorized'],
      ],
      skip: [
        ({ viewer }) => !viewer || viewer.role !== role,
      ],
      afterArchivePages: [
        (
          config.pathNew
          ? ['PageArchiveTableAutoRefresh', {
              path: config.pathNew,
              title: 'New Orders',
              label: 'New Orders',
              icon: 'shopping',
              loadUrl: '/orders',
              renderHeaderTitle: () => (
                <span>
                  New Orders
                  &nbsp;
                  <LoadingOutlined />
                </span>
              ),
              exact: true,
              hidden: true,
              alias: '/orders',
              titleColumnPath: '_id',
              subtitleColumnPath: 'status',
              filters: [
                getFilterWithCol(
                  createActionFilterLink(
                    '/orders',
                    'Show All Orders',
                    { id: 'show-all-orders' },
                  ),
                  24,
                ),
                ...NEW_FILTERS,
              ],
              loadExtractData: response => (
                response
                ? { count: response.data.count, data: response.data.data }
                : { count: 0, data: [] }
              ),
              columns: COLUMNS_ADMIN_NEW,
              redirect: [
                ['redirect.unauthorized'],
              ],
            }]
          : null
        ),
      ].filter(page => page),
      archiveConfig: {
        icon: 'shopping',
        title: 'Orders',
        label: 'Orders',
        loadUrl: '/orders',
        titleColumnPath: '_id',
        subtitleColumnPath: 'status',
        // createButtonPath: `${config.path}/create`,
        getCreateButtonPath: () => null,
        renderBelowTopFilters: props => (
            role !== 'ADMIN'
          ? null
          : (
              <>
                <Divider
                  key="chart-divider"
                  style={{ marginTop: '10px' }}
                />
                <OrdersChartQuery
                  client={props.client}
                  url={`/orders/aggregate/status?${stringifyQuery({
                    query: JSON.stringify({
                      where: { AND: props.queryBuilder.where },
                    }),
                  })}`}
                />
              </>
            )
        ),
      },
      singleConfig: {
        backButtonPaths: [config.path],
      },
      singleCreateConfig: {
        skip: [() => true],
        redirect: [['redirect.always', '/orders']],
      },
      singleEditConfig: {
        headerTitleKey: '_id',
        title: 'Order',
        // renderHeaderTitle: props => (
        //   `ID: ${Page.config.renderHeaderTitle(props)}`
        // ),
        loadUrl: '/orders/:id/?resolve=true&actions=true',
        loadExtractData: (response) => {
          if (response) {
            if (response.data && response.data._id) {
              const data = { ...response.data };
              data.updatedAtDate = new Date(response.data.updatedAt);
              return data;
            }
          }
          return response ? response.data : null;
        },
        actions: [
          ['ActionWithFormModal', {
            label: 'Assign to driver',
            title: 'Assign to driver',
            skip: [orderHasNoAction('ASSIGN')],
            modalProps: {
              centered: true,
              closable: true,
              wrapClassName: 'assign-to-driver-modal',
            },
            fields: [
              ['FieldConnectionSelect', {
                name: 'driver',
                label: 'Type to find driver',
                placeholder: 'Type to find driver', // TODO Somehow, placeholder no works
                url: '/users/drivers',
                // rules: [['validation.isRequired']],
                searchPreviewMatchesInLabel: false,
                getChoiceLabel: ({ data }) => (
                  `${data.name} (${data.provider.name})`
                ),
                renderChoiceLabel: ({ data }) => {
                  if (!data) {
                    return undefined;
                  }
                  const price = getKey(
                    data,
                    `provider.pricing.${data.area._id}.price`,
                    'N/A',
                  );
                  return (
                    <div>
                      <strong>{data.name}</strong>
                      &nbsp;
                      <span>{`from ${data.provider.name}`}</span>
                      <br />
                      <span style={{ fontSize: '12px' }}>
                        {`Area: ${data.area.name} / Price:`}
                        &nbsp;
                        {
                          isFinite(price)
                          ? renderCurrency({
                              prefix: ` ${Types.decimalize(price)}`,
                            })
                          : 'N/A'
                        }
                      </span>
                    </div>
                  );
                },
                extraQueryProps: { populate: { provider: true, area: true } },
                getExtraQueryConditions: props => [Object.assign(
                  {
                    role: 'DRIVER',
                    order: { EQ: null },
                    systemCountry: props.data.systemCountry,
                  },
                  !isArray(props.data.areas) || !props.data.areas.length
                  ? {}
                  : isString(props.data.areas[0])
                  ? { area: { IN: props.data.areas } }
                  : { area: { IN: props.data.areas.map(({ _id }) => _id) } }
                )],
              }],
            ],
            actions: [
              ['ActionWithFormBasedRequest', {
                title: 'Assign',
                skip: [],
                handleSuccess: (_data, props) => props.reload(),
                getRequestConfig: (props, data) => ({
                  url: `orders/${props.data._id}/assign`,
                  method: 'post',
                  data,
                }),
              }],
            ],
          }],
          ...drivingActions,
        ],
        fields: [...FIELDS],
      },
      filters: (
        role === 'ADMIN'
        ? [
            getFilterWithCol(
              createActionFilterLink(
                '/orders/new',
                'Show New Orders',
                { id: 'show-new-orders' },
              ),
              24,
            ),
            ...ALL_FILTERS,
          ]
        : ALL_FILTERS
      ),
      columns: role === 'ADMIN' ? COLUMNS_ADMIN : COLUMNS_DRIVER,
    }]).pages);
    return pages;
  },
  [],
);
