/* eslint-disable one-var, one-var-declaration-per-line, prefer-destructuring, import/no-extraneous-dependencies */

import getKey from 'lodash/get';

import React, { Component, PureComponent } from 'react';
import styled, { css } from 'styled-components';

import QueryComponent from 'hive-admin/src/components/Query';

import Types from '../modules/types';

import {
  formatIndexAmount,
} from './Links';

export const ChartWrapper = styled.div`
  position: relative;
  display: flex;
  height: 300px;
`;

const TypeGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TypeAmounts = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  text-align: center;
  flex: 1;
`;

const TypeAmountInfo = styled.div`
  display: flex;
  height: 70px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;

const TypeAmountInfoLabel = styled.div`
  display: block;
  text-align: center;
`;

const TypeAmountInfoValue = styled.div`
  display: block;
  text-align: center;
`;

const TypeAmount = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  max-width: 30px;
  padding-left: 5px;
  cursor: pointer;
  &:first-child {
    max-width: 25px;
    padding-left: 0px;
  }
`;

const TypeAmountChart = styled.div`
  display: flex;
  flex: 1;
  background: rgb(243, 243, 243);
  transform: scaleX(0);
  transform-origin: 50% 100%;
  transition:
    transform 800ms cubic-bezier(0.230, 1.000, 0.320, 1.000),
    background 200ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
  ${({ active }) => active && css`
    background: #F2C314;
  `}
`;

const TypeAmountChartBottomLine = styled.div`
  display: flex;
  height: 1px;
  width: 100%;
  background: rgb(243, 243, 243);
  ${({ active }) => active && css`
    background: #F2C314;
  `}
`;

const TypeTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 12px;
`;

const TypeTitleText = styled.div`
  display: block;
  text-align: center;
  padding: 5px;
`;

export class TransactionsChartTypeGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAmountKey: props.statuses[0],
      peekAmountKey: null,
    };
  }

  setAmountKey(amountKey, asSelected = true) {
    this.setState((state) => {
      if (asSelected) {
        return {
          selectedAmountKey: state.selectedAmountKey === amountKey
          ? null
          : amountKey,
        };
      }
      return { peekAmountKey: amountKey };
    });
  }

  render() {
    const { label, statuses, data, max } = this.props;
    const { selectedAmountKey, peekAmountKey } = this.state;
    const activeStatus = peekAmountKey || selectedAmountKey;
    return (
      <TypeGroupWrapper>
        <TypeAmountInfo>
          {
            activeStatus
            ? (
                <>
                  <TypeAmountInfoLabel>
                    {Types.ORDER_STATUS_LABELS_MAP[activeStatus]}
                  </TypeAmountInfoLabel>
                  <TypeAmountInfoValue>
                    {
                      formatIndexAmount(
                        getKey(
                          data,
                          `${activeStatus}.sum`,
                          0,
                        ),
                      )
                    }
                  </TypeAmountInfoValue>
                </>
              )
            : null
          }
        </TypeAmountInfo>
        <TypeAmounts>
          {
            statuses.map((status) => {
              const value = getKey(
                data,
                `${status}.sum`,
                0,
              );
              const scale = value / max;
              return (
                <TypeAmount
                  key={status}
                  onMouseEnter={() => this.setAmountKey(status, false)}
                  onMouseLeave={() => this.setAmountKey(null, false)}
                  onClick={() => this.setAmountKey(status, true)}
                >
                  <TypeAmountChart
                    style={{ transform: `scaleY(${scale})` }}
                    active={status === activeStatus}
                  />
                  <TypeAmountChartBottomLine
                    active={status === activeStatus}
                  />
                </TypeAmount>
              );
            })
          }
        </TypeAmounts>
        <TypeTitle>
          <TypeTitleText>
            {label}
          </TypeTitleText>
        </TypeTitle>
      </TypeGroupWrapper>
    );
  }
}

export default class TransactionsChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.max = this.getMax(this.props.data, this.state);
    this.state.data = this.getData(this.props.data, this.state);
  }

  getMax(data, state) {
    if (!data) {
      return state.max || 0;
    }
    return Math.max(...Object.values(data).map(values => values.sum));
  }

  getData(data, state) {
    if (!data) {
      return state.data || {};
    }
    return data;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.data !== props.data) {
      this.setState(state => ({
        max: this.getMax(props.data, state),
        data: this.getData(props.data, state),
      }));
    }
  }

  render() {
    return (
      <ChartWrapper>
        {
          Types.ORDER_STATUS_CHART.map((statuses, i) => {
            const label = Types.ORDER_STATUS_CHART_LABELS[i];
            return (
              <TransactionsChartTypeGroup
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                label={label}
                statuses={statuses}
                data={this.state.data}
                max={this.state.max}
              />
            );
          })
        }
      </ChartWrapper>
    );
  }
}

export class Query extends PureComponent {
  static defaultProps = {
    ChartComponent: TransactionsChart,
  }

  extractData = res => (res ? res.data : null)

  render() {
    const { url, client, ChartComponent } = this.props;
    return (
      <QueryComponent
        url={url}
        client={client}
        method="GET"
        extractData={this.extractData}
      >
        {({ data }) => <ChartComponent data={data} />}
      </QueryComponent>
    );
  }
}
