import getKey from 'lodash/get';

import React, { PureComponent } from 'react';
import path from 'path';
import styled, { css, createGlobalStyle } from 'styled-components';

import {
  UserOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  CarOutlined,
} from '@ant-design/icons';

import Admin from 'hive-admin';
import PageSingle from 'hive-admin/src/components/PageSingle';

import Popover from 'antd/lib/popover';
import Button from 'antd/lib/button';
import Descriptions from 'antd/lib/descriptions';

import Types from '../../modules/types';
import { decimalize } from '../../modules/helpers';
import { renderCurrency } from '../../components/SystemCountry';

import {
  GoogleMapsLink,
  PhoneNumberLink,
} from '../../components/Links';

const useTokenFromPathOrRedirectToLogin = ({
  isInitializing,
  isAuthorized,
  location,
  match,
  client,
}) => {
  if (match && match.params && match.params.token) {
    client.setAccessToken(match.params.token);
    window.location.pathname = path.resolve(location.pathname, '../');
    return false;
  }
  if (!isInitializing && !isAuthorized) {
    return '/login';
  }
  return false;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin: 50px auto; */
  width: 100%;
  /* padding: 40px; */
  /* border-radius: 10px; */
  /* background-color: #fefefe; */
  /* box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); */
  text-align: left;
  .ant-descriptions-item {
    display: flex;
    .ant-descriptions-item-label {
      vertical-align: top;
    }
  }
`;

const Spacer = styled.div`
  height: ${({ height = 0 }) => height}px;
`;

function TitleHTML({ alone, ...props }) {
  // eslint-disable-next-line jsx-a11y/heading-has-content
  return <h1 {...props} />;
}

const Title = styled(TitleHTML)`
  font-size: 40px;
  line-height: 1.2;
  font-weight: 700;
  ${({ alone }) => alone && css`
    margin-bottom: 0px;
  `}
`;

const ActionPopConfirmTitle = styled.div`
  font-size: 20px;
  padding: 10px 5px;
`;

const ActionPopConfirmWrapper = styled.div`
  display: flex;
  width: calc(100vw - 140px);
  max-width: 240px;
  .ant-btn {
    width: 100%;
    &[data-cancel="true"] {
      margin-left: -5px;
      margin-right: 5px;
    }
    &[data-ok="true"] {
      margin-left: 5px;
      margin-right: -5px;
    }
  }
`;

const ActionPopConfirmGlobalStyle = createGlobalStyle`
  body > .action-pop-confirm-container {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    pointer-events: none;
    background-color: transparent;
    transition: background-color 300ms;
    &[data-visible="true"] {
      pointer-events: all;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
`;

class ActionPopConfirm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible: !!visible });
    this.props.container.setAttribute('data-visible', !!visible);
  }

  handleOkClick = (ev) => {
    this.setState({ visible: false });
    this.props.container.setAttribute('data-visible', false);
    this.props.onClick(ev);
  }

  handleCancelClick = () => {
    this.setState({ visible: false });
    this.props.container.setAttribute('data-visible', false);
  }

  handleGetPopupContainer = () => this.props.container

  renderContent() {
    return (
      <ActionPopConfirmWrapper>
        <Button
          type="danger"
          size="large"
          data-cancel="true"
          onClick={this.handleCancelClick}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="large"
          block
          onClick={this.handleOkClick}
          data-ok="true"
          {...this.props.params}
        >
          OK
        </Button>
      </ActionPopConfirmWrapper>
    );
  }

  render() {
    return (
      <Popover
        trigger="click"
        title={(
          <ActionPopConfirmTitle>
            {this.props.title}
          </ActionPopConfirmTitle>
        )}
        content={this.renderContent()}
        visible={this.state.visible}
        getPopupContainer={this.handleGetPopupContainer}
        onVisibleChange={this.handleVisibleChange}
      >
        {this.props.children}
      </Popover>
    );
  }
}

export class PageDriving extends PageSingle {
  static config = {
    ...PageSingle.config,
    ClassName: 'PageDriving',
  }

  constructor(props) {
    super(props);
    this.popoverContainer = window.document.createElement('div');
    this.popoverContainer.setAttribute('class', 'action-pop-confirm-container');
    this.popoverContainer.setAttribute('data-visible', false);
  }

  componentDidMount() {
    super.componentDidMount && super.componentDidMount();
    window.document.body.append(this.popoverContainer);
  }

  componentWillUnmount() {
    super.componentWillUnmount && super.componentWillUnmount();
    window.document.body.removeChild(this.popoverContainer);
  }

  handleActionClick = (event) => {
    const { action, success, result } = getKey(event, 'target.dataset', {});
    // this.setState({ loading: true });
    const hideWorking = Admin.showMessage('Processing', 'loading', 0);
    this.props.client.request({
      url: `orders/${this.props.data._id}/driving/${action}`,
      method: 'POST',
      data: { success: success === 'true' },
    })
    .then(() => {
      hideWorking();
      Admin.showMessage('Done!', 'success', 3);
      if (result && result.length) {
        this.setState({ result });
      } else {
        this.props.reload();
      }
    })
    .catch((error) => {
      hideWorking();
      Admin.showMessage(error.message, 'error', 3);
    });
  }

  renderOrderHeader(order) {
    return (
      <Descriptions title="Order" column={1}>
        <Descriptions.Item label="ID">
          {order._id}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          {Types.ORDER_STATUS_LABELS_MAP[order.status]}
        </Descriptions.Item>
        <Descriptions.Item label="Reference">
          {order.referenceId}
        </Descriptions.Item>
        <Descriptions.Item label="Pickup Reference">
          {order.pickupReferenceId}
        </Descriptions.Item>
        {
          order.cost.cod > 0
          ? (
              <Descriptions.Item label="Cash on delivery">
                {renderCurrency({
                  suffix: ` ${decimalize(order.cost.cod)}`,
                })}
              </Descriptions.Item>
            )
          : null
        }
      </Descriptions>
    );
  }

  renderOrderDetails(title, details) {
    return (
      <Descriptions title={title} column={1}>
        <Descriptions.Item label={<UserOutlined />}>
          {details.name}
        </Descriptions.Item>
        <Descriptions.Item label={<PhoneOutlined />}>
          <PhoneNumberLink
            phoneNumberString={details.phoneNumberString}
          />
        </Descriptions.Item>
        <Descriptions.Item label={<EnvironmentOutlined />}>
          {details.address.lines}
        </Descriptions.Item>
        <Descriptions.Item label={<CarOutlined />}>
          <GoogleMapsLink
            coordinates={details.address.coordinates}
          />
        </Descriptions.Item>
      </Descriptions>
    );
  }

  renderOrderAction(title, action, success, popconfirm = true, result = '') {
    const buttonProps = {
      type: success ? 'primary' : 'danger',
      size: 'large',
      style: { width: '100%', marginBottom: '10px' },
    };
    const actionProps = {
      'data-action': action,
      'data-success': !!success,
      'data-result': result,
    };
    return popconfirm
    ? (
        <ActionPopConfirm
          title={`${title}?`}
          onClick={this.handleActionClick}
          container={this.popoverContainer}
          params={actionProps}
        >
          <Button
            {...buttonProps}
          >
            {title}
          </Button>
        </ActionPopConfirm>
      )
    : (
        <Button
          {...buttonProps}
          {...actionProps}
          onClick={this.handleActionClick}
        >
          {title}
        </Button>
      );
  }

  renderFound() {
    const {
      status,
      pickupDetails,
      deliveryDetails,
      // items,
    } = this.props.data;
    const { result } = this.state || {};
    let content = null;
    if (result) {
      content = <Title alone>{result}</Title>;
    } else {
      switch (status) {
        case 'ASSIGNED':
          content = (
            <>
              <Title>New Delivery</Title>
              <Spacer height={20} />
              {this.renderOrderHeader(this.props.data)}
              <Spacer height={20} />
              {this.renderOrderDetails(
                'Pickup from',
                pickupDetails,
                this.props.data,
              )}
              <Spacer height={20} />
              {this.renderOrderDetails(
                'Deliver to',
                deliveryDetails,
                this.props.data,
              )}
              <Spacer height={20} />
              {this.renderOrderAction(
                'Accept',
                'start',
                true,
                true, // change to false to disable popconfirm
              )}
              {this.renderOrderAction(
                'Deny',
                'start',
                false,
                true, // change to false to disable popconfirm
                'Order Denied',
              )}
            </>
          );
          break;
        case 'PICKUP':
          content = (
            <>
              <Title>Pickup</Title>
              <Spacer height={20} />
              {this.renderOrderHeader(this.props.data)}
              <Spacer height={20} />
              {this.renderOrderDetails(
                'Pickup from',
                pickupDetails,
                this.props.data,
              )}
              <Spacer height={20} />
              {this.renderOrderAction(
                'Picked up',
                'pickup',
                true,
                true, // change to false to disable popconfirm
              )}
              {this.renderOrderAction(
                'Failed pickup',
                'pickup',
                false,
                true, // change to false to disable popconfirm
                'Pickup Failed',
              )}
            </>
          );
          break;
        case 'DELIVERY':
          content = (
            <>
              <Title>Delivery</Title>
              <Spacer height={20} />
              {this.renderOrderHeader(this.props.data)}
              <Spacer height={20} />
              {this.renderOrderDetails(
                'Deliver to',
                deliveryDetails,
                this.props.data,
              )}
              <Spacer height={20} />
              {this.renderOrderAction(
                'Delivered',
                'deliver',
                true,
                true, // change to false to disable popconfirm
              )}
              {this.renderOrderAction(
                'Failed delivery',
                'deliver',
                false,
                true, // change to false to disable popconfirm
                'Delivery Failed',
              )}
            </>
          );
          break;
        default:
          content = (
            <Title alone>
              {
                status === 'PICKUP_FAILED'
                ? 'Pickup Failed'
                : status === 'DELIVERY_FAILED'
                ? 'Delivery Failed'
                : status === 'DELIVERED'
                ? 'Order Completed'
                : 'Order Not Found'
              }
            </Title>
          );
          break;
      }
    }
    if (!content) {
      return (
        <>
          <ActionPopConfirmGlobalStyle />
          <Title alone>Order not found</Title>
        </>
      );
    }
    return (
      <>
        <ActionPopConfirmGlobalStyle />
        <Wrapper>
          {content}
        </Wrapper>
      </>
    );
  }
}

export default [config => PageDriving.create(config), {
  title: 'Driving',
  icon: null,
  path: '/driving/:id/:token?',
  hideSidebar: true,
  hideHeader: true,
  hidden: [() => true],
  redirect: [useTokenFromPathOrRedirectToLogin],
  activity: 'edit',
  editConfig: { actions: [] },
  useTrash: false,
  headerTitle: 'Driving',
  loadUrl: '/orders/:id/driving',
  saveMethod: 'post',
  loadExtractData: response => (response ? response.data : null),
  notFoundMessage: 'Order not found!',
}];
