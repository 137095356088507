import isArray from 'lodash/isArray';

import React, { cloneElement, createRef } from 'react';
import styled from 'styled-components';

import Admin from 'hive-admin';
import ActionWithRequest from 'hive-admin/src/components/ActionWithRequest';
import FieldUploadRefs from 'hive-admin/src/components/FieldUploadRefs';

import {
  CameraOutlined,
} from '@ant-design/icons';

import AntButton from 'antd/lib/button';
import AntPopover from 'antd/lib/popover';

const PopoverHTML = ({ className, ...props }) => (
  <AntPopover
    overlayClassName={className}
    {...props}
  />
);

const Popover = styled(PopoverHTML)`
  width: calc(100% - 22px - 22px);
  max-width: 200px;
  .ant-popover-title {
    font-size: 16px;
    text-align: center;
    padding: 12px 16px;
    color: ${({ theme }) => theme.less.textColor};
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const FieldWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > span {
    width: 100%;
    .ant-upload {
      width: 100%;
      .ant-btn-group {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
    .ant-upload-list {
      .ant-upload-list-item {
        /* margin-top: 0px; */
        .anticon-close {
          opacity: 1;
          font-size: 18px;
          top: 2px;
        }
      }
      .ant-upload-list-item-info .anticon-loading,
      .ant-upload-list-item-info .anticon-paper-clip {
        top: 3px;
      }
    }
  }
`;

export class FieldActionWithImageUploadRefs extends FieldUploadRefs {
  renderAddButton() {
    return (
      <div ref={this.handleAddButtonWrapperRef}>
        <AntButton.Group>
          <AntButton data-action="upload">
            Upload Image
          </AntButton>
        </AntButton.Group>
      </div>
    );
  }
}

export default class ActionWithImage extends ActionWithRequest {
  static config = {
    ...ActionWithRequest.config,
    imagePopoverSkip: false,
    getImagePopoverSkip: (props) => (
      props.imagePopoverSkip === true || Admin.test(
        props.imagePopoverSkip || [],
        props,
        true,
        true,
      )
    ),
    imagePopoverRequired: false,
    getImagePopoverRequired: (props) => (
      props.imagePopoverRequired === true || Admin.test(
        props.imagePopoverRequired || [],
        props,
        true,
        true,
      )
    ),
    imagePopoverTitle: 'Capture Event',
    getImagePopoverTitle: props => props.imagePopoverTitle,
    imagePopoverSubmitLabel: 'Submit',
    getImagePopoverSubmitLabel: props => props.imagePopoverSubmitLabel,
  }

  static create(initialConfig) {
    const { render, handleSuccess, ...config } = super.create(initialConfig);
    config.imagePopoverRef = createRef();
    config.imagePopoverValueRef = createRef();
    config.field = Admin.compileFromLibrary(
      ['FieldActionWithImageUploadRefs', {
        name: 'image',
        label: 'Image',
        listType: 'text',
        accept: 'image/jpg,image/jpeg,image/png',
        transformations: [
          ['GM', { command: 'compress', maxWidth: 1920 }],
        ],
        fileListFromValue: (...args) => (
          FieldActionWithImageUploadRefs
          .config
          .fileListFromValue(...args)
          .map(file => Object.assign(
            file,
            { name: file.status === 'done' ? 'Image' : 'Uploading...' },
          ))
        ),
      }],
    );
    config.imagePopoverSkip = isArray(config.imagePopoverSkip)
    ? Admin.compileFromLibrary(config.imagePopoverSkip, true)
    : !!config.imagePopoverSkip;
    config.imagePopoverRequired = isArray(config.imagePopoverRequired)
    ? Admin.compileFromLibrary(config.imagePopoverRequired, true)
    : !!config.imagePopoverRequired;
    config.handleSuccess = (data, props) => {
      if (props.imagePopoverRef && props.imagePopoverRef.current) {
        props.imagePopoverRef.current.setImagePopoverVisible(false);
      }
      if (handleSuccess) {
        return handleSuccess(data, props);
      }
      return null;
    };
    config.render = props => render({
      ...props,
      field: config.field,
      ref: config.imagePopoverRef,
      imagePopoverRef: config.imagePopoverRef,
      imagePopoverValueRef: config.imagePopoverValueRef,
      imagePopoverSkip: config.imagePopoverSkip,
      imagePopoverRequired: config.imagePopoverRequired,
      imagePopoverTitle: config.imagePopoverTitle,
      imagePopoverSubmitLabel: config.imagePopoverSubmitLabel,
      handleSuccess: config.handleSuccess,
    });
    return config;
  }

  constructor(props) {
    super(props);
    this.state = this.state || {};
    this.state.image = null;
    this.state.visible = false;
  }

  componentWillUnmount() {
    this.unmounted = true;
    if (this.props.imagePopoverValueRef) {
      this.props.imagePopoverValueRef.current = null;
    }
    super.componentWillUnmount && super.componentWillUnmount();
  }

  setImagePopoverVisible(visible) {
    if (!this.unmounted) {
      this.setState({ visible: !!visible });
    }
  }

  handleUploadChange = (file) => {
    if (!this.unmounted) {
      if (this.props.imagePopoverValueRef) {
        this.props.imagePopoverValueRef.current = file || null;
      }
      this.setState({ image: file });
    }
  }

  handleVisibleChange = (visible) => {
    if (!this.unmounted) {
      this.setState({ visible, image: null });
    }
  }

  render() {
    const action = super.render();
    if (action && !this.props.getImagePopoverSkip(this.props)) {
      return (
        <Popover
          title={(
            <>
              <CameraOutlined />
              &nbsp;
              &nbsp;
              {this.props.getImagePopoverTitle(this.props)}
            </>
          )}
          trigger="click"
          visible={this.state.visible}
          onVisibleChange={this.handleVisibleChange}
          content={(
            <Wrapper>
              <FieldWrapper>
                {this.props.field.render({
                  ...this.props,
                  value: this.state.image,
                  onChange: this.handleUploadChange,
                })}
              </FieldWrapper>
              <AntButton
                type="primary"
                disabled={(
                  this.props.getImagePopoverRequired(this.props)
                  && (!this.state.image || this.state.image.status !== 'done')
                )}
                onClick={this.handleClick}
                block
              >
                {this.props.getImagePopoverSubmitLabel(this.props)}
              </AntButton>
            </Wrapper>
          )}
        >
          {cloneElement(action, {
            onClick: null,
          })}
        </Popover>
      );
    }
    return action;
  }
}

Admin.addToLibrary(
  'FieldActionWithImageUploadRefs',
  config => FieldActionWithImageUploadRefs.create(config),
);

Admin.addToLibrary(
  'ActionWithImage',
  config => ActionWithImage.create(config),
);
