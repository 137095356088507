import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

import Button from 'antd/lib/button';

export const ActionFilterLink = styled(Button)`
  text-align: left;
  padding: 0px 10px;
`;

export default function createActionFilterLink(to, title, {
  id,
  linkProps = {},
  buttonProps = {},
} = {}) {
  return ['Action', {
    id: id || to,
    name: id || to,
    section: 'top',
    renderAction: () => (
      <Link to={to} {...linkProps}>
        <ActionFilterLink key={id || to} block {...buttonProps}>
          {title}
        </ActionFilterLink>
      </Link>
    ),
  }];
}
