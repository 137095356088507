import React from 'react';
import styled, { keyframes } from 'styled-components';

import moment from 'moment';
import isArray from 'lodash/isArray';

import { Link } from 'react-router-dom';

import {
  SubjectLink,
  AmountLink,
  OrderStatusLink,
  DateLink,
  renderOrderAmount,
} from '../../components/Links';

import Types from '../../modules/types';

const Dot = styled.span`
  /* animation: ${keyframes`
    0% { opacity: 1; }
    50% { opacity: 0.1; }
  `} step-start 0.7s infinite; */
`;

export const columnId = {
  path: '_id',
  title: 'ID',
  render: (record, column, props) => (
    <span>
      {
        (
          props.viewer
          && props.viewer.role === 'DRIVER'
          && Types.ORDER_STATUS_ACTIVE.includes(record.status)
        )
        ? <Dot>{'• '}</Dot>
        : null
      }
      <Link to={`/orders/${record._id}`}>
        {record._id}
      </Link>
    </span>
  ),
};

export const columnStatus = {
  path: 'status',
  render: (record, column, props) => (
    <OrderStatusLink
      location={props.location}
      subject={record}
    />
  ),
};

export const columnProvider = {
  path: 'provider',
  title: 'Provider',
  render: (record, column, props) => (
    record.provider
    ? (
        <SubjectLink
          location={props.location}
          subject={record.provider}
          pathname="/providers"
          links={[{
            filterName: 'provider',
            targetLabel: 'orders',
            pathname: '/orders',
          }, {
            filterName: 'provider',
            searchLabelVerb: 'from',
            targetLabel: 'drivers',
            pathname: '/users/drivers',
          }]}
          searchLabel="provider"
          filterName="provider"
        />
      )
    : 'N/A'
  ),
};

export const columnDriver = {
  path: 'driver',
  title: 'Driver',
  render: (record, column, props) => (
    record.driver
    ? (
        <SubjectLink
          location={props.location}
          subject={record.driver}
          pathname="/users"
          searchLabel="driver"
          filterName="driver"
        />
      )
    : 'N/A'
  ),
};

export const columnCostPackmanShipping = {
  path: 'cost.shipping',
  title: 'Shipping (Packman)',
  align: 'right',
  render: record => renderOrderAmount(record, 'cost.shipping'),
};

export const columnCostProviderShipping = {
  path: 'cost.provider',
  title: 'Shipping (Provider)',
  align: 'right',
  render: record => renderOrderAmount(record, 'cost.provider'),
};

export const columnCostPayment = {
  path: 'cost.payment',
  title: 'COD Charges',
  align: 'right',
  render: record => renderOrderAmount(record, 'cost.payment'),
};

export const columnCostRevenue = {
  path: 'cost.revenue',
  title: 'Revenue',
  align: 'right',
  render: (record, column, props) => (
    <AmountLink
      location={props.location}
      subject={record}
      valuePath="cost.revenue"
    />
  ),
};

export const columnCostItemsWithVat = {
  path: 'cost.items',
  title: 'Products',
  align: 'right',
  render: record => renderOrderAmount(
    { value: (record.cost.items || 0) + (record.cost.vat || 0) },
    'value',
  ),
};

export const columnCostCOD = {
  path: 'cost.cod',
  title: 'COD',
  align: 'right',
  render: record => renderOrderAmount(record, 'cost.cod'),
};

export const columnAreas = {
  path: 'areas',
  title: 'Areas',
  render: (record, column, props) => (
    isArray(record.areas) && record.areas.length
    ? record.areas.reduce(
        (agr, area, i, areas) => {
          agr.push(
            <SubjectLink
              key={area._id}
              location={props.location}
              subject={area}
              pathname="/areas"
              links={[{
                filterName: 'area',
                targetLabel: 'orders',
                pathname: '/orders',
              }, {
                filterName: 'area',
                targetLabel: 'drivers',
                pathname: '/users/drivers',
              }]}
              searchLabel="area"
              filterName="area"
            />
          );
          if (i < areas.length - 1) {
            agr.push(<span key={`comma${area._id}`}>, </span>);
          }
          return agr;
        },
        [],
      )
    : 'N/A'
  ),
};

export const columnCreatedAt = {
  path: 'createdAt',
  title: 'Created At',
  render: (record, column, props) => (
    <DateLink
      date={record.createdAt}
      location={props.location}
    >
      {moment(record.createdAt).format(
        'dddd, MMMM Do YYYY [at] h:mm a'
      )}
    </DateLink>
  ),
};

export const ADMIN = [
  columnId,
  columnStatus,
  columnProvider,
  columnDriver,
  columnAreas,
  columnCostPackmanShipping,
  columnCostProviderShipping,
  columnCostPayment,
  columnCostRevenue,
  columnCostItemsWithVat,
  columnCostCOD,
  columnCreatedAt,
];

export const ADMIN_NEW = [
  columnId,
  columnAreas,
  columnCostPackmanShipping,
  columnCostItemsWithVat,
  columnCostCOD,
  columnCreatedAt,
];

export const DRIVER = [
  columnId,
  columnStatus,
  columnCostCOD,
  columnCreatedAt,
];
