import getKey from 'lodash/get';
import isArray from 'lodash/isArray';

import React, {
  // useEffect,
} from 'react';
import styled from 'styled-components';

import moment from 'moment-timezone';

import List from 'antd/lib/list';
import Avatar from 'antd/lib/avatar';
import Descriptions from 'antd/lib/descriptions';
import {
  UserOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  CarOutlined,
  MailOutlined,
  CameraOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import { Redirect } from 'react-router-dom';

import { decimalize } from '../../modules/helpers';
import { renderCurrency } from '../../components/SystemCountry';

import {
  EmailLink,
  GoogleMapsLink,
  PhoneNumberLink,
  OrderStatusLink,
  DateLink,
  SubjectLink,
} from '../../components/Links';

import Types from '../../modules/types';

const OrderDescription = styled(Descriptions)`
  .ant-descriptions-title {
    font-size: 22px;
  }
  .ant-descriptions-item {
    display: flex;
  }
  .ant-list-item {
    border-bottom: 0px;
  }
`;

const OrderDescriptionSectionSeparator = styled.div`
  height: 20px;
`;

const SectionTitleIconCameraOutlined = styled(CameraOutlined)`
  padding: 10px;
`;

const createDrivingPointDetails = (data, orderReference) => (
  [
    /* eslint-disable react/jsx-key */
    [<UserOutlined />, getKey(data, 'name')],
    [<PhoneOutlined />, (
      // eslint-disable-next-line react/jsx-key
      <PhoneNumberLink phoneNumberString={getKey(data, 'phoneNumberString')} />
    )],
    [<MailOutlined />, (
      // eslint-disable-next-line react/jsx-key
      <EmailLink email={getKey(data, 'email')} orderId={orderReference} />
    )],
    [<EnvironmentOutlined />, getKey(data, 'address.lines')],
    [<CarOutlined />, (
      // eslint-disable-next-line react/jsx-key
      <GoogleMapsLink coordinates={getKey(data, 'address.coordinates')} />
    )],
    [<InfoCircleOutlined />, (
      `Ref: ${orderReference}`
    )],
    /* eslint-enable react/jsx-key */
  ]
);

// function Helper({ reload }) {
//   useEffect(
//     () => {
//       reload && reload();
//     },
//     [reload],
//   );
//   return null;
// }

// eslint-disable-next-line import/prefer-default-export
export const FIELDS = [
  ['FieldReact', {
    name: 'packman-order-single-fields',
    renderContent: (props) => {
      const isAdmin = getKey(props.viewer, 'role', null) === 'ADMIN';
      const isDriver = getKey(props.viewer, 'role', null) === 'DRIVER';
      const isDriving = !!props.driving;
      if (!isDriving && isDriver && props.data) {
        if (Types.ORDER_STATUS_DRIVER.includes(props.data.status) === false) {
          return <Redirect key="driver-redirect" to="/orders" />;
        }
        // if (
        //   props.drivingOrder
        //   && props.drivingOrder._id === props.data._id
        //   && (
        //       props.data.updatedAtDate.getTime()
        //     < props.drivingOrder.updatedAtDate.getTime()
        //   )
        // ) {
        //   return <Helper reload={props.reload} />;
        // }
        // if (
        //   props.drivingOrder === null
        //   && Types.ORDER_STATUS_SETTLED.includes(props.data.status) === false
        // ) {
        //   return <Helper reload={props.reload} />;
        // }
      }
      const content = [];
      if (!isDriving) {
        content.push(
          ['Info', [
            ['Status', (
              // eslint-disable-next-line react/jsx-key
              <OrderStatusLink
                location={props.location}
                subject={props.data}
              />
            )],
            (
              isAdmin
              ? ['Matching Areas', (
                  isArray(props.data.areas) && props.data.areas.length
                  ? props.data.areas.reduce(
                      (agr, area, i, areas) => {
                        if (area && area._id) {
                          agr.push(
                            <SubjectLink
                              key={area._id}
                              location={props.location}
                              subject={area}
                              pathname="/areas"
                              links={[{
                                filterName: 'area',
                                targetLabel: 'orders',
                                pathname: '/orders',
                              }, {
                                filterName: 'area',
                                targetLabel: 'drivers',
                                pathname: '/users/drivers',
                              }]}
                              searchLabel="area"
                              filterName="area"
                            />
                          );
                          if (i < areas.length - 1) {
                            agr.push(<span key={`comma${area._id}`}>, </span>);
                          }
                        }
                        return agr;
                      },
                      [],
                    )
                  : 'N/A'
                )]
              : null
            ),
            (
              isAdmin && props.data.provider
              ? (
                  ['Provider', (
                    // eslint-disable-next-line react/jsx-key
                    <SubjectLink
                      location={props.location}
                      subject={props.data.provider}
                      pathname="/providers"
                      links={[{
                        filterName: 'provider',
                        targetLabel: 'orders',
                        pathname: '/orders',
                      }, {
                        filterName: 'provider',
                        searchLabelVerb: 'from',
                        targetLabel: 'drivers',
                        pathname: '/users/drivers',
                      }]}
                      searchLabel="provider"
                      filterName="provider"
                    />
                  )]
                )
              : null
            ),
            (
              isAdmin && props.data.driver
              ? (
                  ['Driver', (
                    // eslint-disable-next-line react/jsx-key
                    <SubjectLink
                      location={props.location}
                      subject={props.data.driver}
                      pathname="/users"
                      searchLabel="driver"
                      filterName="driver"
                    />
                  )]
                )
              : null
            ),
            ['Date', (
              // eslint-disable-next-line react/jsx-key
              <DateLink
                date={props.data.createdAt}
                location={props.location}
                pathname="/orders"
              >
                {moment(props.data.createdAt).format(
                  'dddd, MMMM Do YYYY [at] h:mm a'
                )}
              </DateLink>
            )],
          ]],
        );
      }
      content.push(
        [( // eslint-disable-next-line react/jsx-key
          <span>
            Pickup Details
            {
              props.data.pickupImage
              ? (
                  <a
                    href={props.data.pickupImage.src}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SectionTitleIconCameraOutlined />
                  </a>
                )
              : null
            }
          </span>
        ), createDrivingPointDetails(
          props.data.pickupDetails,
          props.data.pickupReferenceId,
        ), 'pickup-details'],
      );
      content.push(
        [( // eslint-disable-next-line react/jsx-key
          <span>
            Delivery Details
            {
              props.data.deliveryImage
              ? (
                  <a
                    href={props.data.deliveryImage.src}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SectionTitleIconCameraOutlined />
                  </a>
                )
              : null
            }
          </span>
        ), createDrivingPointDetails(
          props.data.deliveryDetails,
          props.data.referenceId,
        ), 'delivery-details'],
      );
      content.push(
        ['Cost', (
          isAdmin
          ? [
              ['Items', renderCurrency({
                suffix: ` ${decimalize(getKey(props.data, 'cost.items', 0))}`,
              })],
              ['Delivery', renderCurrency({
                suffix: ` ${
                  decimalize(getKey(props.data, 'cost.shipping', 0))
                }`,
              })],
              ['Provider', renderCurrency({
                suffix: ` ${
                  decimalize(getKey(props.data, 'cost.provider', 0))
                }`,
              })],
              ['Payment charge', renderCurrency({
                suffix: ` ${decimalize(
                  getKey(props.data, 'cost.payment', 0)
                )}`,
              })],
              ['Cash on delivery', renderCurrency({
                suffix: ` ${decimalize(
                  getKey(props.data, 'cost.cod', 0),
                )}`,
              })],
              ['Revenue', renderCurrency({
                suffix: ` ${decimalize(
                  getKey(props.data, 'cost.revenue', 0),
                )}`,
              })],
            ]
          : [
              ['Cash on delivery', renderCurrency({
                suffix: ` ${decimalize(
                  getKey(props.data, 'cost.cod', 0),
                )}`,
              })],
            ]
        )],
      );
      content.push(
        ['Items', (
          // eslint-disable-next-line react/jsx-key
          <Descriptions.Item>
            <List
              itemLayout="horizontal"
              dataSource={props.data.items}
              renderItem={item => (
                <List.Item key={item.name}>
                  <List.Item.Meta
                    title={item.name}
                    description={renderCurrency({
                      prefix: `${item.quantity} x `,
                      suffix: ` ${Types.decimalize(item.price)}`,
                    })}
                    avatar={(
                      <Avatar
                        src={item.image}
                        size="large"
                        shape="square"
                      />
                    )}
                  />
                </List.Item>
              )}
            />
          </Descriptions.Item>
        ), false],
      );
      return content.reduce(
        (sections, section, index, initialSectionsArray) => {
          if (section) {
            const [title, subContent, key] = section;
            sections.push(
              <OrderDescriptionSectionSeparator
                key={`${key || title}-separator`}
              />
            );
            sections.push(
              <OrderDescription
                key={key || title}
                title={title}
                column={1}
              >
                {
                  isArray(subContent)
                  ? subContent.reduce(
                      (items, item) => {
                        if (item) {
                          items.push(
                            <Descriptions.Item key={item[0]} label={item[0]}>
                              {item[1]}
                            </Descriptions.Item>
                          );
                        }
                        return items;
                      },
                      [],
                    )
                  : subContent
                }
              </OrderDescription>
            );
          }
          return sections;
        },
        [],
      );
    },
  }],
];
