import getKey from 'lodash/get';

import Admin from 'hive-admin';

import tests from '../../helpers/tests';

import Types from '../../modules/types';

import {
  USERS as COLUMNS_USERS,
  DRIVERS as COLUMNS_DRIVERS,
} from './columns';

import {
  USERS as FILTERS_USERS,
  DRIVERS as FILTERS_DRIVERS,
} from './filters';

const {
  viewerIsNotAdmin,
  userIsNotDriver,
} = tests;

const skipPassword = ({ data, viewer, activity }) => (
  !viewer
  || (
    /@thehive\.rs$/.test(viewer.email) === false
    && (
      activity === 'create'
      || (
        activity === 'edit'
        && getKey(data, '_id', 0) !== getKey(viewer, '_id', 1)
      )
    )
  )
);

const userHasNoProvider = [
  'condition.testFieldValue',
  'provider',
  '==',
  null,
];

const isNotCreateActivity = ['condition.check', {
  path1: 'activity',
  op: '!==',
  value2: 'create',
}];

export default Admin.compileFromLibrary(['GroupResource', {
  title: 'Users',
  icon: 'user',
  path: '/users',
  redirect: [
    ['redirect.unauthorized'],
  ],
  archiveConfig: {
    title: 'Users',
    label: 'Users',
    icon: 'user',
    createButtonPath: '/users/create',
    subtitleColumnPath: 'email',
    filters: FILTERS_USERS,
    columns: COLUMNS_USERS,
    hidden: [
      ['redirect.unauthorized'],
      ['redirect.authorizedNonAdmin'],
    ],
    redirect: [
      ['redirect.unauthorized'],
      ['redirect.authorizedNonAdmin'],
    ],
  },
  afterArchivePages: [
    ['PageArchiveTable', {
      label: 'Drivers',
      title: 'Drivers',
      path: '/users/drivers',
      alias: '/users',
      exact: true,
      hidden: true,
      redirect: [
        ['redirect.unauthorized'],
        ['redirect.authorizedNonAdmin'],
      ],
      loadUrl: '/users/drivers',
      filters: FILTERS_DRIVERS,
      loadExtractData: response => (
        response
        ? { count: response.data.count, data: response.data.data }
        : { count: 0, data: [] }
      ),
      columns: COLUMNS_DRIVERS,
    }],
  ],
  singleConfig: {
    hidden: true,
    alias: '/users',
    backButtonPaths: ['/users'],
    getBackButtonPaths: props => (
        props.viewer && props.viewer.role === 'ADMIN'
      ? props.backButtonPaths
      : false
    ),
  },
  singleEditConfig: {
    actions: [
      ['ActionSave'],
      ['ActionDelete', {
        skip: [viewerIsNotAdmin],
      }],
      ['ActionWithRequest', {
        name: 'activate',
        title: 'Activate',
        skip: [
          ['condition.check', { path1: 'data.active', value2: true }],
        ],
        getRequestConfig: props => ({
          url: `users/actions/activate?id=${props.data._id}`,
          method: 'POST',
        }),
        handleSuccess: (data, props) => props.reload(),
      }],
      ['ActionWithRequest', {
        name: 'deactivate',
        title: 'Deactivate',
        skip: [
          ['condition.check', { path1: 'viewer._id', path2: 'data._id' }],
          viewerIsNotAdmin,
          ['condition.check', { path1: 'data.active', value2: false }],
        ],
        getRequestConfig: props => ({
          url: `users/actions/deactivate?id=${props.data._id}`,
          method: 'POST',
        }),
        handleSuccess: (data, props) => props.reload(),
      }],
      ['ActionWithRequest', {
        name: 'send-activation-email',
        title: 'Send Activation Email',
        skip: [
          ['condition.check', { path1: 'data.active', value2: true }],
        ],
        getRequestConfig: props => ({
          url: `users/actions/activate?id=${props.data._id}&email=true`,
          method: 'POST',
        }),
      }],
    ],
  },
  fields: [
    ['FieldText', {
      name: 'name',
      rules: [['validation.isRequired'], ['validation.isName']],
    }],
    ['FieldText', {
      name: 'email',
      autoComplete: 'new-email',
      rules: [['validation.isRequired'], ['validation.isEmail']],
    }],
    ['FieldSelect', {
      name: 'role',
      rules: [['validation.isRequired']],
      choices: Types.ROLE,
      initialValue: 'ADMIN',
      disabled: [isNotCreateActivity],
      virtual: [isNotCreateActivity],
      skip: [viewerIsNotAdmin],
    }],
    ['Divider', {
      name: 'passwordDivider',
      skip: [skipPassword],
    }],
    ['FieldText', {
      name: 'password',
      type: 'password',
      autoComplete: 'new-password',
      rules: [
        ['validation.isPassword'],
      ],
      skip: [skipPassword],
    }],
    ['FieldText', {
      name: 'confirmPassword',
      type: 'password',
      label: 'Confirm Password',
      rules: [
        ['validation.isPasswordSame'],
      ],
      skip: [skipPassword],
      virtual: true,
    }],
    // Driver options
    ['Divider', {
      name: 'driverDivider',
      skip: [
        userIsNotDriver,
        viewerIsNotAdmin,
      ],
    }],
    ['FieldConnectionSelect', {
      name: 'provider',
      placeholder: 'Select the provider driver belongs to',
      url: '/providers',
      searchPaths: ['name'],
      rules: [['validation.isRequired']],
      disabled: [props => props.activity !== 'create'],
      virtual: [props => props.activity !== 'create'],
      skip: [
        userIsNotDriver,
        viewerIsNotAdmin,
      ],
    }],
    ['FieldConnectionSelect', {
      name: 'area',
      placeholder: 'Select the top-most area driver operates in',
      url: '/areas',
      getExtraQueryConditions: props => ([{
        provider: props.form.getFieldValue('provider'),
      }]),
      searchPaths: ['name'],
      rules: [['validation.isRequired']],
      skip: [
        userIsNotDriver,
        userHasNoProvider,
        viewerIsNotAdmin,
      ],
    }],
    // Driver info
    ['Divider', {
      name: 'driverInfoDivider',
      skip: [userIsNotDriver],
    }],
    ['FieldText', {
      name: 'phoneNumberString',
      label: 'Phone Number',
      rules: [['validation.isRequired']],
      skip: [userIsNotDriver],
    }],
  ],
}]).pages;
