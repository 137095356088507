import moment from 'moment';

import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Link } from 'react-router-dom';

import {
  SubjectLink,
  // AmountLink,
  // OrderStatusLink,
  // DateLink,
  // renderOrderAmount,
} from '../../components/Links';

export const columnName = { path: 'name', link: '/users/:_id' };

export const columnNameDriver = {
  path: 'name',
  title: 'Name',
  render: (record, column, props) => (
    record.provider
    ? (
        <SubjectLink
          location={props.location}
          subject={record}
          pathname="/users"
          searchLabel="driver"
          filterName="driver"
        />
      )
    : 'N/A'
  ),
};

export const columnEmail = 'email';

export const columnRole = 'role';

export const columnActive = {
  path: 'active',
  title: 'Status',
  render: record => (record.active ? 'ACTIVE' : 'INACTIVE'),
};

export const columnOrder = {
  path: 'order',
  render: record => (
    record.order
    ? <Link to={`/orders/${record.order}`}>{record.order}</Link>
    : 'Available'
  ),
};

export const columnProvider = {
  path: 'provider',
  title: 'Provider',
  render: (record, column, props) => (
    record.provider
    ? (
        <SubjectLink
          location={props.location}
          subject={record.provider}
          pathname="/providers"
          searchLabel="provider"
          filterName="provider"
        />
      )
    : 'N/A'
  ),
};

export const columnArea = {
  title: 'Area',
  path: 'area',
  render: (record, column, props) => (
    record.area
    ? (
        <SubjectLink
          location={props.location}
          subject={record.area}
          pathname="/areas"
          searchLabel="area"
          filterName="area"
        />
      )
    : 'N/A'
  ),
};

export const columnCreatedAt = {
  path: 'createdAt',
  title: 'Joined At',
  render: (record) => (
    moment(record.createdAt).format(
      'dddd, MMMM Do YYYY [at] h:mm a'
    )
  ),
};

export const USERS = [
  columnName,
  columnEmail,
  columnRole,
  columnActive,
  columnCreatedAt,
];

export const DRIVERS = [
  columnNameDriver,
  columnOrder,
  columnProvider,
  columnArea,
  columnCreatedAt,
];
