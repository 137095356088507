import React, { Component } from 'react';

import getKey from 'lodash/get';

import isArray from 'lodash/isArray';

import Table from 'antd/lib/table';

import Admin from 'hive-admin';
import Query from 'hive-admin/src/components/Query';
import Field from 'hive-admin/src/components/Field';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Link } from 'react-router-dom';

import Input from 'antd/lib/input';
import Checkbox from 'antd/lib/checkbox';

import { renderCurrency } from './SystemCountry';

class PricingTable extends Component {
  handlePriceInputChange = (event) => {
    const { nativeEvent, target } = event;
    const { checked, value } = target;
    const {
      pricingId,
      pricingInput,
    } = nativeEvent.target.dataset;
    const pricing = this.props.data.find(
      ({ _id }) => pricingId === _id
    );
    if (pricing) {
      const newValue = {
        ...(this.props.value || {}),
      };
      if (pricingInput === 'supported') {
        if (!checked) {
          newValue[pricingId] = {
            ...(newValue[pricingId] || {}),
            supported: false,
          };
        } else {
          newValue[pricingId] = {
            ...(newValue[pricingId] || {}),
            supported: true,
          };
        }
      } else if (pricingInput === 'value') {
        newValue[pricingId] = {
          ...(newValue[pricingId] || {}),
          price: value,
        };
      }
      this.props.onChange(newValue);
    }
  }

  render() {
    return (
      <Table
        dataSource={this.props.data}
        size="middle"
        rowKey="_id"
        showHeader
        pagination={false}
        bordered
        locale={{ emptyText: 'No areas defined' }}
      >
        <Table.Column
          key="supported"
          align="center"
          render={(area) => {
            const disabled = !area.active;
            const checked = !!getKey(this.props.value, `${area._id}.supported`);
            return (
              <Checkbox
                size="small"
                data-pricing-id={area._id}
                data-pricing-input="supported"
                onChange={this.handlePriceInputChange}
                disabled={disabled}
                checked={checked}
              />
            );
          }}
        />
        <Table.Column
          key="area"
          title="Area"
          render={area => (
            <Link to={`/areas/${area._id}`}>
              {area.name}
            </Link>
          )}
        />
        <Table.Column
          key="price"
          title="Price"
          render={(area) => {
            const disabled = !getKey(this.props.value, `${area._id}.supported`);
            const value = getKey(this.props.value, `${area._id}.price`);
            return (
              <Input
                size="small"
                data-pricing-id={area._id}
                data-pricing-input="value"
                onChange={this.handlePriceInputChange}
                suffix={renderCurrency()}
                placeholder={`Packman: ${area.price}`}
                disabled={disabled}
                value={value}
              />
            );
          }}
        />
      </Table>
    );
  }
}

export default class FieldAreaPricing extends Field {
  static config = { ...Field.config }

  extractData = response => (
    response && response.data && isArray(response.data.data)
    ? response.data.data
    : []
  )

  render() {
    return (
      <Query
        client={this.props.client}
        extractData={this.extractData}
        url={`/areas?query=${encodeURIComponent(JSON.stringify({
          sort: { name: 1 },
          limit: 500,
        }))}`}
      >
        {queryProps => (
          <PricingTable
            data={queryProps.data}
            onChange={this.props.onChange}
            value={this.props.value}
          />
        )}
      </Query>
    );
  }
}

Admin.addToLibrary(
  'FieldAreaPricing',
  config => FieldAreaPricing.create(config),
);
