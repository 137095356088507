import isArray from 'lodash/isArray';

import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import AntMenu from 'antd/lib/menu';

import Admin from './components/Admin';

import {
  Switcher as SystemCountrySwitcher,
} from './components/SystemCountry';

import tests from './helpers/tests';

import './helpers/library';

import 'hive-admin/src/components/FieldGooglePolygon';

import './antd.less';
import './admin.less';

import './components/ActionWithImage';
import './components/FieldAreaPricing';
import './components/FieldWorkingHours';
import './components/FieldGoogleAddress';

import UsersPages from './routes/users';
import AreasPages from './routes/areas';
import ProvidersPages from './routes/providers';
import OrdersPages from './routes/orders';
import SettingsPages from './routes/settings';

import DrivingPage from './routes/driving';
import TrackingPage from './routes/tracking';

import theme from './theme';

import { renderLogo, renderSidebarLogo } from './components/Logo';

const { viewerIsNotAdmin } = tests;

const {
  REACT_APP_SERVER_HOST,
  REACT_APP_SERVER_PROTOCOL,
  REACT_APP_HTTP_PORT,
  REACT_APP_API_PATH,
  REACT_APP_PUBLIC_URL = '',
} = process.env;

const SERVER_URL = `${
  REACT_APP_SERVER_PROTOCOL
}://${
  REACT_APP_SERVER_HOST
}${
  REACT_APP_HTTP_PORT === '80' ? '' : `:${REACT_APP_HTTP_PORT}`
}`;

const base = window.ADMIN_BASE = `${REACT_APP_PUBLIC_URL}`;
const restBase = (
    REACT_APP_HTTP_PORT === '80'
  ? `${SERVER_URL}${REACT_APP_API_PATH}`
  : `${REACT_APP_API_PATH}`
);

const extraPageConfig = {
  getHideSidebar: props => (
    props.hideSidebar || !props.viewer || props.viewer.role === 'DRIVER'
  ),
  getHideFloating: props => (
    !props.viewer || props.viewer.role !== 'DRIVER'
  ),
};

const admin = Admin.create({
  base,
  restBase,
  restBaseServer: `${SERVER_URL}${REACT_APP_API_PATH}`,
  titlePrefix: 'Packman | ',
  sidebarProps: { renderLogo: renderSidebarLogo },
  passwordSetSuccessRedirectPath: '/login',
  accountActivationSuccessRedirectPath: '/login',
  validateViewer: (viewer) => {
    if (['ADMIN', 'DRIVER'].indexOf(viewer.role) === -1) {
      throw new Error('Invalid credentials');
    }
    return viewer;
  },
  structure: [
    ['PageLogin', {
      renderBeforeForm: renderLogo,
      redirect: [['redirect.authorized']],
    }],
    ['Group', {
      path: '/SYSTEM_LANGUAGE_PICKER',
      renderNavigationMenuItem: (item, navigation) => (
        <AntMenu.Item
          key={item.id}
          onMouseEnter={navigation.handleMenuItemMouseEnter}
        >
          <SystemCountrySwitcher
            searchParams={navigation.props.searchParams}
            location={navigation.props.location}
          />
        </AntMenu.Item>
      ),
      exact: true,
      redirect: [
        ['redirect.unauthorized'],
        ['redirect.always', '/users/me'],
      ],
      skip: [
        viewerIsNotAdmin,
      ],
    }],
    ['Page', {
      hidden: true,
      path: '/',
      exact: true,
      redirect: [['redirect.always', '/orders']],
    }],
    ['PagePasswordReset', {
      renderBeforeForm: renderLogo,
      redirect: [['redirect.authorized']],
    }],
    ['PagePasswordSet', {
      renderBeforeForm: renderLogo,
    }],
    ['PageAccountActivation', {
      renderBeforeForm: renderLogo,
    }],
    ...UsersPages,
    ...AreasPages,
    ...ProvidersPages,
    ...OrdersPages,
    ...SettingsPages,
    DrivingPage,
    TrackingPage,
    ['Page404', {
      redirect: [['redirect.unauthorized']],
    }],
  ].map((page) => {
    if (isArray(page)) {
      return [page[0], {
        ...(page[1] || {}),
        ...extraPageConfig,
      }];
    }
    return {
      ...page,
      ...extraPageConfig,
    };
  }),
});

ReactDOM.render(
  (
    <ThemeProvider theme={theme}>
      {admin}
    </ThemeProvider>
  ),
  document.getElementById('root'),
);
