import Admin from 'hive-admin';

import { renderCurrency } from '../../components/SystemCountry';
import { decimalize } from '../../modules/helpers';

export default Admin.compileFromLibrary(['GroupResource', {
  title: 'Areas',
  icon: 'appstore',
  path: '/areas',
  redirect: [
    ['redirect.unauthorized'],
    ['redirect.authorizedNonAdmin'],
  ],
  filters: [
    ['FilterRegexSearch', {
      id: 'search',
      paths: ['name'],
      caseInsensitive: true,
      section: 'top',
    }],
    ['FilterPagination', {
      id: 'pagination',
      section: 'bottom',
    }],
  ],
  archiveConfig: {
    title: 'Areas',
    label: 'Areas',
    icon: 'appstore',
    createButtonPath: '/areas/create',
    hidden: [
      ['redirect.unauthorized'],
      ['redirect.authorizedNonAdmin'],
    ],
  },
  singleConfig: {
    hidden: true,
    alias: '/areas',
    backButtonPaths: ['/areas'],
  },
  columns: [
    { path: 'name', link: '/areas/:_id' },
    {
      path: 'price',
      render: record => renderCurrency({
        prefix: `${decimalize(record.price)} `,
      }),
    },
    {
      path: 'active',
      render: record => (record.active ? 'ACTIVE' : 'NOT ACTIVE'),
    },
  ],
  fields: [
    ['FieldText', {
      name: 'name',
      rules: [['validation.isRequired']],
    }],
    ['FieldSwitch', {
      name: 'active',
      initialValue: true,
      rules: [['validation.isRequired']],
    }],
    ['FieldText', {
      name: 'price',
      type: 'number',
      initialValue: 0,
      addonBefore: renderCurrency(),
      rules: [
        ['validation.isRequired'],
        ['validation.isNumber', { min: 0 }],
      ],
    }],
    ['FieldGooglePolygon', {
      name: 'polygon',
      rules: [['validation.isRequired']],
      mapContainerHeight: '350px',
      polygonExtraParameters: {
        strokeColor: '#F2C314',
        fillColor: '#F2C31480',
      },
      initialMapConfig: {
        // UAE
        zoom: 6,
        center: {
          lng: 54.71720030880409,
          lat: 24.659754289090323,
        },
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
      },
    }],
  ],
}]).pages;
