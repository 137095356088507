import React from 'react';
import isArray from 'lodash/isArray';
import isFinite from 'lodash/isFinite';
import getKey from 'lodash/get';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  stringify as stringifyQuery,
  parse as parseQuery,
} from 'query-string';

import moment from 'moment';

import Popover from 'antd/lib/popover';

import { renderCurrency } from './SystemCountry';

import Types from '../modules/types';

import { decimalize } from '../modules/helpers';

export function lowerCaseRestLetters(word) {
  return `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`;
}

export function formatOrderStatus(subject) {
  return Types.ORDER_STATUS_LABELS_MAP[(
    subject.status
    ? subject.status
    : subject
  )];
}

export function formatIndexAmount(amount) {
  return `${
    (
      isFinite(amount)
      ? amount.toFixed(0)
      : `${amount}`
    ).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }`;
}

export function renderOrderAmount(subject, valuePath) {
  const output = (
    // eslint-disable-next-line react/jsx-key
    <span style={{ whiteSpace: 'nowrap' }}>
      {renderCurrency({
        prefix: `${decimalize(getKey(subject, valuePath, 0))} `,
      })}
    </span>
  );
  return (
    <div style={{ textAlign: 'right' }}>
      {output}
    </div>
  );
}

export function getLinkWithSearchFields(
  location,
  fields = [],
  extras = {},
) {
  const search = parseQuery(
    location
    ? location.search.slice(1)
    : ''
  );
  fields.forEach(({ name, value, replace = false }) => {
    if (replace) {
      search[name] = value;
    } else {
      search[name] = (
        !search[name]
        ? []
        : isArray(search[name])
        ? search[name]
        : [search[name]]
      )
      .filter(testValue => (
        isArray(value)
        ? !value.includes(testValue)
        : value !== testValue
      ));
      search[name].push(...isArray(value) ? value : [value]);
    }
  });
  return { ...location, ...extras, search: `?${stringifyQuery(search)}` };
}

export function getSubjectUrl(pathname, subject) {
  return `${pathname}/${subject._id || subject}`;
}

export function SubjectLink({
  location,
  subject,
  filterName,
  searchLabel,
  children,
  pathname,
  links,
}) {
  if (!subject) {
    return 'N / A';
  }
  return (
    <Popover
      title={null}
      content={(
        isArray(links)
        ? (
            <div>
              {
                links.reduce(
                  (
                    agr,
                    {
                      filterName: linksFilterName,
                      targetLabel: linksTargetLabel,
                      searchLabelVerb: linksSearchLabelVerb = 'with',
                      pathname: linksPathname,
                    },
                    index,
                  ) => {
                    const key = `${linksPathname}-${filterName}`;
                    agr.push(
                      <span key={key}>
                        <span>
                          {`Show ${linksTargetLabel} ${linksSearchLabelVerb} `}
                        </span>
                        <Link
                          to={getLinkWithSearchFields(
                            location,
                            [{ name: linksFilterName, value: subject._id }],
                            { pathname: linksPathname },
                          )}
                        >
                          {`this ${searchLabel}`}
                        </Link>
                      </span>
                    );
                    if (index < links.length - 1) {
                      agr.push(
                        <span key={`divider-${key}`}>
                          &nbsp;
                          /
                          &nbsp;
                        </span>
                      );
                    }
                    return agr;
                  },
                  [],
                )
              }
            </div>
          )
        : (
            <div>
              <span>Show orders with </span>
              <Link
                to={getLinkWithSearchFields(
                  location,
                  [{ name: filterName, value: subject._id }],
                  { pathname: '/orders' },
                )}
              >
                {`this ${searchLabel}`}
              </Link>
            </div>
          )
      )}
    >
      <Link to={getSubjectUrl(pathname, subject)}>
        {
          children
          ? children
          : `${subject.name}`
        }
      </Link>
    </Popover>
  );
}

export function GoogleMapsLink({
  coordinates,
}) {
  return (
    <a
      href={`https://www.google.com/maps/dir/?api=1&destination=${
        coordinates[1]
      },${
        coordinates[0]
      }`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Launch Google Maps
    </a>
  );
}

export function EmailLink({
  email,
  subject,
  orderId,
}) {
  return (
    <a
      href={`mailto:${email}${
        subject
        ? `?subject=${subject}`
        : orderId
        ? `?subject=${`Order #${orderId}`}`
        : ''
      }`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {email}
    </a>
  );
}

export function PhoneNumberLink({
  phoneNumberString,
}) {
  return (
    <a
      href={`tel:${phoneNumberString}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {phoneNumberString}
    </a>
  );
}

export function DateLink({
  location,
  date,
  children,
  pathname = '/orders',
}) {
  date = moment(date);
  return (
    <Popover
      title={null}
      content={(
        <div>
          Show orders this:
          <span> </span>
          {
            ['day', 'week', 'month'].reduce(
              (agr, range, i, array) => {
                agr.push(
                  <Link
                    key={range}
                    to={getLinkWithSearchFields(
                      location,
                      [
                        {
                          name: 'from',
                          value: date.startOf(range).toJSON(),
                          replace: true,
                        },
                        {
                          name: 'to',
                          value: date.endOf(range).toJSON(),
                          replace: true,
                        },
                      ],
                      { pathname },
                    )}
                  >
                    {range}
                  </Link>
                );
                if (i < array.length - 1) {
                  agr.push(<span key={`${range}-separator`}> / </span>);
                }
                return agr;
              },
              [],
            )
          }
        </div>
      )}
    >
      {
        children
        ? children
        : date.fromNow()
      }
    </Popover>
  );
}

export function OrderStatusLink({
  location,
  subject,
  searchLabel = 'Show orders with this status',
  pathname = '/orders',
  children,
}) {
  return (
    <Popover
      title={null}
      content={(
        <Link
          to={getLinkWithSearchFields(
            location,
            [{ name: 'status', value: subject.status }],
            { pathname },
          )}
        >
          {searchLabel}
        </Link>
      )}
    >
      {
        children
        ? children
        : formatOrderStatus(subject)
      }
    </Popover>
  );
}

export function TransactionIDLink({
  // location,
  subject,
}) {
  const link = (
    <Link to={getSubjectUrl('/transactions', subject._id)}>
      {subject._id}
    </Link>
  );
  const [path, prefix] = (
      subject.reversalOf
    ? ['reversalOf', `Reversal of ${
        Types.TRANSACTION_TYPE_LABELS[subject.reversalOfType]
      }`]
    : subject.reversedBy
    ? ['reversedBy', 'Reversed by']
    : []
  );
  if (!path) {
    return link;
  }
  return (
    <Popover
      title={null}
      content={(
        <Link to={getSubjectUrl('/transactions', subject[path])}>
          {`${prefix} ${subject[path]._id}`}
        </Link>
      )}
    >
      {link}
    </Popover>
  );
}

export function AmountLink({
  location,
  subject,
  noun = 'orders',
  pathname = '/orders',
  valuePath = 'cost.revenue',
}) {
  return (
    <Popover
      title={null}
      content={(
        <div>
          <span>{`Show ${noun} `}</span>
          <Link
            to={getLinkWithSearchFields(
              location,
              [{
                name: 'above',
                value: getKey(subject, valuePath),
                replace: true,
              }],
              { pathname },
            )}
          >
            above
          </Link>
          <span> / </span>
          <Link
            to={getLinkWithSearchFields(
              location,
              [{
                name: 'under',
                value: getKey(subject, valuePath),
                replace: true,
              }],
              { pathname },
            )}
          >
            under
          </Link>
          <span> this amount</span>
        </div>
      )}
    >
      {renderOrderAmount(subject, valuePath)}
    </Popover>
  );
}
