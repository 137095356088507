/* eslint-disable import/prefer-default-export */

import React from 'react';
import isArray from 'lodash/isArray';
import moment from 'moment';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Link } from 'react-router-dom';
import {
  getLinkWithSearchFields,
} from '../../components/Links';

import getFilterWithCol from '../../helpers/getFilterWithCol';

import { renderCurrency } from '../../components/SystemCountry';
import Types from '../../modules/types';

const getValueForFieldDefault = value => (
  value && value.length
  ? value
  : undefined
);

export const pagination = ['FilterPagination', {
  id: 'pagination',
  section: 'bottom',
}];

export const population = ['FilterHidden', {
  id: 'population',
  build: builder => builder.add(
    'populate',
    { provider: true, driver: true, areas: true },
  ),
}];

export const limitTo50 = ['FilterHidden', {
  id: 'limitTo50',
  build: builder => builder.add('limit', 50),
}];

export const limitToNew = ['FilterHidden', {
  id: 'limitToNew',
  build: builder => builder.add('where', { status: 'NEW' }),
}];

export const limitToReal = ['FilterHidden', {
  id: 'limitToReal',
  build: builder => builder.add(
    'where',
    { status: { IN: Types.ORDER_STATUS_REAL } },
  ),
}];

export const limitToDriver = ['FilterHidden', {
  id: 'limitToDriver',
  build: builder => builder.add(
    'where',
    { status: { IN: Types.ORDER_STATUS_DRIVER } },
  ),
}];

export const filterId = ['FilterField', {
  id: 'id',
  label: null, // 'ID',
  section: 'top',
  buildQuery: (value, builder) => value && builder.add(
    'where',
    { _id: { REGEX: value, OPTIONS: 'i' } },
  ),
  getValueForField: value => value || '',
  getValueForQuery: (value) => {
    value = !value
    ? undefined
    : value.target
    ? value.target.value
    : value;
    return !value || !value.length
    ? undefined
    : value;
  },
  field: ['FieldText', {
    name: 'id',
    placeholder: 'Search ID',
  }],
}];

export const [filterStatusAdmin, filterStatusDriver] = [
  [Types.ORDER_STATUS_REAL],
  [Types.ORDER_STATUS_DRIVER],
].map(([statuses]) => ['FilterField', {
  id: 'status',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { status: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldSelect', {
    name: 'status',
    placeholder: 'Status',
    mode: 'multiple',
    allowClear: true,
    choices: (
      statuses
      .filter(status => !['SUBMITTING'].includes(status))
      .map(status => ({
        label: Types.ORDER_STATUS_LABELS_MAP[status],
        value: status,
      }))
    ),
  }],
}]);

export const filterPayment = ['FilterField', {
  id: 'payment',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { payment: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldSelect', {
    name: 'payment',
    placeholder: 'Payment',
    mode: 'multiple',
    allowClear: true,
    choices: Types.PAYMENT_METHOD.map(value => ({
      label: Types.PAYMENT_METHOD_LABELS_MAP[value],
      value,
    })),
  }],
}];

export const filterArea = ['FilterField', {
  id: 'area',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { areas: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'area',
    url: '/areas',
    placeholder: 'Areas',
    mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
  }],
}];

export const filterProvider = ['FilterField', {
  id: 'provider',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { provider: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'provider',
    url: '/providers',
    placeholder: 'Providers',
    mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
  }],
}];

export const filterDriver = ['FilterField', {
  id: 'driver',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { driver: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'driver',
    url: '/users/drivers',
    placeholder: 'Drivers',
    mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
  }],
}];

export const [filterFrom, filterTo] = [
  ['from', 'After this date', 'GTE'],
  ['to', 'Before this date', 'LTE'],
].map(([id, placeholder, operator]) => [
  'FilterField', {
    id,
    label: null,
    section: 'top',
    buildQuery: (value, builder) => value && builder.add(
      'where',
      { createdAt: { [operator]: value } },
    ),
    getValueForField: value => (value ? moment(value) : undefined),
    getValueForQuery: value => (
      value
      ? (
          id === 'from'
          ? moment(value).startOf('day')
          : moment(value).endOf('day')
        ).toJSON()
      : undefined
    ),
    field: ['FieldDatePicker', {
      name: id,
      placeholder,
      showToday: false,
      renderExtraFooter: () => {
        const now = moment();
        return (
          <div>
            <span>
              {id === 'from' ? 'Start of this: ' : 'End of this: '}
            </span>
            {[
              ['Day', 'day'],
              ['Week', 'week'],
              ['Month', 'month'],
              ['Year', 'year'],
            ].reduce(
              (agr, [label, range], i, array) => {
                agr.push(
                  <span key={range}>
                    <Link
                      to={getLinkWithSearchFields(
                        // eslint-disable-next-line no-undef
                        window.location,
                        [
                          {
                            name: id,
                            value: (
                                id === 'from'
                              ? now.startOf(range)
                              : now.endOf(range)
                            ).toJSON(),
                            replace: true,
                          },
                        ],
                        { pathname: '/orders' },
                      )}
                    >
                      {label}
                    </Link>
                  </span>
                );
                if (i < array.length - 1) {
                  // eslint-disable-next-line react/no-array-index-key
                  agr.push(<span key={`sep${i}`}> / </span>);
                }
                return agr;
              },
              [],
            )}
          </div>
        );
      },
    }],
  },
]);

export const [filterAbove, filterUnder] = [
  ['above', 'Revenue above', 'GTE'],
  ['under', 'Revenue under', 'LTE'],
].map(([id, placeholder, operator]) => [
  'FilterField', {
    id,
    label: null,
    section: 'top',
    buildQuery: (value, builder) => value && builder.add(
      'where',
      { 'cost.revenue': { [operator]: parseFloat(value, 10) || 0 } },
    ),
    getValueForField: value => value || '',
    getValueForQuery: value => (
      !value
      ? undefined
      : value.target
      ? value.target.value
      : value
    ),
    field: ['FieldText', {
      name: id,
      placeholder,
      addonBefore: renderCurrency(),
      style: { textAlign: 'right' },
      textAlign: 'right',
      align: 'right',
    }],
  },
]);

export const [filterSortAdmin, filterSortDriver] = [
  [
    [{ label: 'Sort by revenue', value: 'revenue' }],
  ], [
    [],
  ],
].map(([extraChoices]) => ['FilterField', {
  id: 'sort',
  label: null,
  section: 'top',
  VALUES_MAP: {
    latest: { updatedAt: -1 },
    date: { createdAt: -1 },
    revenue: { 'cost.revenue': -1 },
  },
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('sort', { ...filter.VALUES_MAP[value] })
    : builder.add('sort', { ...filter.VALUES_MAP.latest })
  ),
  getValueForField: value => (
    value && value.length
    ? value
    : undefined
  ),
  field: ['FieldSelect', {
    name: 'sort',
    placeholder: 'Sort',
    prepareValueForInput: value => (value ? value : 'latest'),
    choices: [{
      label: 'Sort by latest',
      value: 'latest',
    }, {
      label: 'Sort by date',
      value: 'date',
    }, ...extraChoices],
  }],
}]);

export const ADMIN = [
  ...[
    [filterId, 8],
    [filterStatusAdmin, 8],
    [filterSortAdmin, 8],

    [filterProvider, 12, true, 1],
    [filterDriver, 12, true, 2],

    [filterArea, 12, true, 3],
    [filterPayment, 12, true, 4],

    [filterFrom, 12, true, 5],
    [filterAbove, 12, true, 7],

    [filterTo, 12, true, 6],
    [filterUnder, 12, true, 8],
  ].map(args => getFilterWithCol(...args)),

  pagination,
  population,
  limitToReal,
];

export const ADMIN_NEW = [
  ...[
    [filterId, 12],
    [filterSortAdmin, 12],

    [filterArea, 12, true, 1],
    [filterPayment, 12, true, 2],

    [filterFrom, 12, true, 4],
    [filterAbove, 12, true, 6],

    [filterTo, 12, true, 5],
    [filterUnder, 12, true, 7],
  ].map(args => getFilterWithCol(...args)),

  limitTo50,
  population,
  limitToNew,
];

export const DRIVER = [
  ...[
    [filterId, 8],
    [filterStatusDriver, 8],
    [filterSortDriver, 8],
  ].map(args => getFilterWithCol(...args)),

  pagination,
  population,
  limitToDriver,
];
