module.exports.decimalize = (number, decimalSeparator = '.') => {
  const thousandSeparator = decimalSeparator === '.' ? ',' : '.';
  return number.toFixed(2)
  .split('')
  .reverse()
  .join('')
  .split('.')
  .map((value, i) => (
    i === 1
    ? value
      .replace(/(.{3})/g, `$1${thousandSeparator}`)
      .replace(new RegExp(`\\${thousandSeparator}$`, 'g'), '')
    : value
  ))
  .join(decimalSeparator)
  .split('')
  .reverse()
  .join('');
};

module.exports.createMockableFunction = (mock, realFn, mockFn) => {
  mockFn = mockFn || realFn;
  realFn = realFn || mockFn;
  const useCb = mock ? mockFn : realFn;
  const cb = (...args) => useCb(...args);
  cb.real = realFn;
  cb.mock = cb.fake = mockFn;
  return cb;
};
