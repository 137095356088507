import Admin from 'hive-admin';

import { renderCurrency } from '../../components/SystemCountry';

export default [
  Admin.compileFromLibrary(['PageSingle', {
    path: '/settings',
    icon: 'tool',
    title: 'Settings',
    topTitleKey: null,
    activity: 'edit',
    url: '/settings/general',
    hidden: [
      ['redirect.unauthorized'],
      ['redirect.authorizedNonAdmin'],
    ],
    redirect: [
      ['redirect.unauthorized'],
      ['redirect.authorizedNonAdmin'],
    ],
    actions: [
      ['ActionSave'],
    ],
    fields: [
      ['FieldText', {
        name: 'prices.cod',
        label: 'Cost of COD',
        type: 'number',
        addonBefore: renderCurrency(),
        rules: [
          ['validation.isRequired'],
          ['validation.isNumber', {
            min: 0,
            message: 'Field must be a number greater or equal to 0',
          }],
        ],
      }],
      ['Divider', { name: 'hoursDivider' }],
      ['FieldWorkingHours', {
        name: 'hours',
        label: 'Working hours',
        rules: [['validation.isRequired']],
      }],
    ],
  }]),
];
