import ms from 'ms';
import moment from 'moment-timezone';
import COUNTRIES from '../assets/countries.json';

const Types = {};

Types.createValues = (
  name,
  values = [],
  {
    keyId = 'id',
    getId = value => value[keyId],
    keyLabel = 'label',
    getLabel = value => value[keyLabel],
  } = {},
) => values.reduce(
  (agr, value) => {
    const id = getId(value);
    const label = getLabel(value);
    agr[name] = agr[name] || [];
    agr[name].push(id);
    const nameList = `${name}_LIST`;
    agr[nameList] = agr[nameList] || [];
    agr[nameList].push(value);
    const nameMap = `${name}_MAP`;
    agr[nameMap] = agr[nameMap] || {};
    agr[nameMap][id] = value;
    const nameLabels = `${name}_LABELS`;
    agr[nameLabels] = agr[nameLabels] || [];
    agr[nameLabels].push(label);
    const nameLabelsMap = `${name}_LABELS_MAP`;
    agr[nameLabelsMap] = agr[nameLabelsMap] || {};
    agr[nameLabelsMap][id] = label;
    const nameConstants = `${name}_CONST`;
    agr[nameConstants] = agr[nameConstants] || {};
    agr[nameConstants][id] = id;
    return agr;
  },
  {},
);

Types.COUNTRIES = COUNTRIES;

Types.COUNTRIES_MAP = COUNTRIES.reduce(
  (agr, country) => { agr[country.iso3a2] = country; return agr; },
  {},
);

Types.COUNTRY = 'ae';

Types.TIMEZONE = 'Asia/Dubai';

Object.assign(
  Types,
  Types.createValues('SYSTEM_COUNTRY', [
    ['ae', 'AED', 'Asia/Dubai'],
    ['om', 'OMR', 'Asia/Dubai'],
  ].map(([id, currency, timezone, config = {}]) => ({
    id,
    country: id,
    label: Types.COUNTRIES_MAP[id].name,
    currency,
    timezone,
    ...config,
  }))),
  {
    getSystemCountry: systemCountry => (
      Types.SYSTEM_COUNTRY_MAP[systemCountry]
    ),
  },
);

Types.ROLE = [
  'ADMIN',
  'DRIVER',
];

Types.PAYMENT_METHOD = [
  'CARD',
  'COD_CASH',
  // 'COD_CARD',
];

Types.PAYMENT_METHOD_LABELS = [
  'Card online',
  'Cash on delivery',
  // 'Card on delivery',
];

Types.PAYMENT_METHOD_LABELS_MAP = Types.PAYMENT_METHOD.reduce(
  (agr, type, index) => {
    agr[type] = Types.PAYMENT_METHOD_LABELS[index];
    return agr;
  },
  {},
);

Types.ORDER_STATUS = [
  'SUBMITTING',
  'PENDING',
  'NEW',
  'ASSIGNED',
  'PICKUP',
  'PICKUP_FAILED',
  'DELIVERY',
  'DELIVERY_FAILED',
  'DELIVERED',
];

Types.ORDER_STATUS_LABELS = [
  'Not Available',
  'Pending',
  'New',
  'Assigned',
  'On a pickup',
  'Pickup failed',
  'On a delivery',
  'Delivery failed',
  'Delivered',
];

Types.ORDER_STATUS_LABELS_MAP = Types.ORDER_STATUS.reduce(
  (agr, orderStatus, index) => {
    agr[orderStatus] = Types.ORDER_STATUS_LABELS[index];
    return agr;
  },
  {},
);

Types.ORDER_STATUS_REAL = [
  'NEW',
  'ASSIGNED',
  'PICKUP',
  'PICKUP_FAILED',
  'DELIVERY',
  'DELIVERY_FAILED',
  'DELIVERED',
];

Types.ORDER_STATUS_DRIVER = [
  'PICKUP',
  'PICKUP_FAILED',
  'DELIVERY',
  'DELIVERY_FAILED',
  'DELIVERED',
];

Types.ORDER_STATUS_ACTIVE = [
  'PICKUP',
  'DELIVERY',
];

Types.ORDER_STATUS_SETTLED = [
  'PICKUP_FAILED',
  'DELIVERY_FAILED',
  'DELIVERED',
];

Types.ORDER_STATUS_CHART = [
  ['NEW', 'ASSIGNED'],
  ['DELIVERY', 'PICKUP'],
  ['DELIVERED'],
  ['PICKUP_FAILED', 'DELIVERY_FAILED'],
];

Types.ORDER_STATUS_CHART_LABELS = [
  'New',
  'In Delivery',
  'Delivered',
  'Failed',
];

Types.AUTOASSIGN_ENABLED = true;
Types.AUTOASSIGN_TRIES = 3;
Types.AUTOASSIGN_LASTS = ms('30s');
// Types.AUTOASSIGN_LASTS = ms('10m');

Types.DRIVER_LOCATION_LASTS = ms('15s');

Types.CURRENCY = 'AED';

Types.MOCK_WORKING_HOURS = `${
  process.env.REACT_APP_MOCK_WORKING_HOURS
  || process.env.MOCK_WORKING_HOURS
}` === 'true';

Types.DEFAULT_WORKING_HOURS = new Array(7).join('.').split('.').map(
  (_, i) => (i > 4 ? [-1] : [8, 20])
);

Types.getWorkingHoursOpen = (hours) => {
  if (Types.MOCK_WORKING_HOURS) {
    return true;
  }
  const now = moment().tz(Types.TIMEZONE);
  const [day, hour] = [now.day(), now.hour()];
  if (
    hours[day].length !== 2
    || hours[day][0] > hour
    || hours[day][1] <= hour
  ) {
    return false;
  }
  return true;
};

Types.decimalize = (number, decimalSeparator = '.') => {
  const thousandSeparator = decimalSeparator === '.' ? ',' : '.';
  return number.toFixed(2)
  .split('')
  .reverse()
  .join('')
  .split('.')
  .map((value, i) => (
    i === 1
    ? value
      .replace(/(.{3})/g, `$1${thousandSeparator}`)
      .replace(new RegExp(`\\${thousandSeparator}$`, 'g'), '')
    : value
  ))
  .join(decimalSeparator)
  .split('')
  .reverse()
  .join('');
};

Types.getRoundedAmount = (amount, rounding = 2) => (
  Math.ceil(amount * (10 ** rounding)) / (10 ** rounding)
);

export default Types;
